import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class QueriesService {

  public selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this.selectedLocationId = id);
  }

  private base_url = 'api/v1/admin';
  private getAllUrl = environment.apiUrl + this.base_url +'/allQueries';
  private getAllLeadsUrl = environment.apiUrl + this.base_url +'/allLeads';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  private _options = {
    headers: this.headers, 
    withCredentials: true
  };

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAll (space_ids): Promise<any> {
    let data = {
      space_ids
    }
    return this.http.post(this.getAllUrl, JSON.stringify(data),{ headers: this.headers, withCredentials: true })
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  getAllLeads (): Promise<any> {
    return this.http.get(this.getAllLeadsUrl,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }
}
