<div class="page-wrapper">
  <!--Sidebar Menu Starts-->
  <aside class="menu-sidebar js-right-sidebar d-block d-lg-none">
    <div class="logo">
      <a routerLink="#">
        <img src="assets/images/logo.png" alt="Listagram" />
      </a>
    </div>
    <div class="menu-sidebar__content js-scrollbar2 ps ps--active-y">
      <!-- <div class="account-img">
        <img src="assets/images/clients/reviewer-4.png" alt="Steve Austin" />
      </div> -->
      <!-- <h4 class="name">Howdy, Steve!</h4> -->
      <nav class="navbar-sidebar2">
        <ul class="list-unstyled navbar__list">
          <li>
            <a routerLink="/home/spaces" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Spaces
            </a>
          </li>
          <li>
            <a routerLink="/home/add-space" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Add Space
            </a>
          </li>
          <!-- <li class="has-sub">
            <a class="js-arrow" routerLink="#">
              <i class="ion-social-buffer-outline"></i>My Listings
            </a>
            <ul class="list-unstyled navbar__sub-list js-sub-list">
              <li>
                <a routerLink="db-my-listing.html">Active</a>
                <span class="inbox-num active">6</span>
              </li>
              <li>
                <a routerLink="db-my-listing.html">Pending</a>
                <span class="inbox-num pending">8</span>
              </li>
            </ul>
          </li>-->
          <li>
            <a routerLink="/home/managers" [routerLinkActive]="['active']">
              <i class="ion-ios-gear-outline"></i>Managers
            </a>
          </li>

          <li>
            <a routerLink="/home/workers" [routerLinkActive]="['active']">
              <i class="ion-ios-copy-outline"></i>Workers</a
            >
          </li>
          <li>
            <a routerLink="/home/leads" [routerLinkActive]="['active']">
              <i class="ion-ios-copy-outline"></i>Leads</a
            >
          </li>
          <li>
            <a (click)="sendProposalDialog()">
              <i class="ion-ios-email-outline"></i>Send Proposal
            </a>
          </li>
          <li>
            <a routerLink="/home/queries" [routerLinkActive]="['active']">
              <i class="ion-ios-email-outline"></i>Queries</a
            >
            <!--<span class="inbox-num pending">3</span>-->
          </li>
          <li>
            <a routerLink="/home/teamcabins" [routerLinkActive]="['active']">
              <i class="ion-ios-email-outline"></i>Teamcabins</a
            >
            <!--<span class="inbox-num pending">3</span>-->
          </li>
          <li>
            <a routerLink="/home/meeting-rooms" [routerLinkActive]="['active']">
              <i class="ion-ios-email-outline"></i>Meeting Rooms</a
            >
            <!--<span class="inbox-num pending">3</span>-->
          </li>
          <li>
            <a routerLink="/home/faqs" [routerLinkActive]="['active']">
              <i class="ionicons ion-help"></i>FAQs</a
            >
            <!--<span class="inbox-num pending">3</span>-->
          </li>
          <!-- <li class="has-sub">
            <a class="js-arrow" routerLink="#">
              <i class="ion-ios-star-outline"></i>Reviews
            </a>
            <ul class="list-unstyled navbar__sub-list js-sub-list">
              <li>
                <a routerLink="db-recieve-reviews.html">
                  Recieved Reviews</a>
                <span class="inbox-num active">6</span>
              </li>
              <li>
                <a routerLink="db-submit-reviews.html">
                  Submitted reviews</a>
                <span class="inbox-num pending">8</span>
              </li>
            </ul>
          </li> -->
          <!-- <li>
            <a routerLink="db-my-profile.html">
              <i class="ion-ios-person-outline"></i>My profile</a>
          </li>
          <li>
            <a routerLink="db-invoices.html">
              <i class="ion-ios-printer-outline"></i>Invoices</a>
          </li>-->
          <li>
            <a (click)="openLogoutDialog()">
              <i class="ion-ios-upload-outline"></i>Logout</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  <!--Sidebar Menu ends-->
  <!--Dashboard content Wrapper starts-->
  <div class="dash-content-wrap">
    <!-- Top header starts-->
    <!--  <header class="db-top-header">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-sm-6 col-8">
            <div class="header-button">
              <div class="header-button-item has-noti js-item-menu">
                <i class="ion-ios-bell-outline"></i>
                <div class="notifi-dropdown js-dropdown">
                  <div class="notifi__title">
                    <p>You have 2 Notifications</p>
                  </div>
                  <div class="notifi__item">
                    <div class="bg-c1 red">
                      <i class="icofont-check"></i>
                    </div>
                    <div class="content">
                      <p>Your listing <b>Hotel Ocean Paradise</b> has been approved!</p>
                      <span class="date">5min ago</span>
                    </div>
                  </div>
                  <div class="notifi__item">
                    <div class="bg-c1 green">
                      <i class="icofont-ui-message"></i>
                    </div>
                    <div class="content">
                      <p>You have 3 unread Messages</p>
                      <span class="date">5min ago</span>
                    </div>
                  </div>
                  <div class="notify-bottom text-center pad-tb-20">
                    <a routerLink="#">View All Notification</a>
                  </div>
                </div>
              </div>
              <div class="setting-menu js-right-sidebar d-none d-lg-block">
                <div class="account-dropdown__body">
                  <div class="account-dropdown__item">
                    <a routerLink="db.html">
                      <i class="ion-ios-gear-outline"></i>Dashboard</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a routerLink="db-my-listing.html">
                      <i class="ion-social-buffer-outline"></i>Listings</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a routerLink="db-bookings.html">
                      <i class="ion-ios-copy-outline"></i>Bookings</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a routerLink="db-recieve-reviews.html">
                      <i class="ion-ios-star-outline"></i>Reviews</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a routerLink="db-messages.html">
                      <i class="ion-ios-email-outline"></i>Inbox</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a routerLink="db-my-profile.html">
                      <i class="ion-ios-person-outline"></i>Profile</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a routerLink="#">
                      <i class="ion-ios-upload-outline"></i>Logout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header> -->
    <div [hidden]="!objLoaderStatus">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div
      class="header-button-item js-sidebar-btn"
      *ngIf="isMobile"
      style="padding: 10px; display: flex"
    >
      <span style="flex: 1"
        ><i style="font-size: 20px" class="ion-android-menu"></i> &nbsp;
        &nbsp;</span
      >
      <span style="flex: 2"
        ><img
          src="assets/images/logo.png"
          style="border-radius: 0px; height: inherit; width: inherit"
          alt="Listagram"
        />
      </span>
    </div>
    <div class="dash-content" style="height: 100%">
      <div class="container-fluid"></div>
      <router-outlet></router-outlet>
    </div>
    <!-- <div class="dash-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="copyright">
              <p>© 2019 Listagram . All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--Dashboard footer ends-->
  </div>
</div>
