import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  private getAllCitiesUrl = environment.apiUrl + `api/v1/getAllCities`;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getCities(): Promise<any> {
    return this.http
      .get(this.getAllCitiesUrl, { headers: this.headers })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }
}
