<div>
  <h2 style="font-weight: 300; font-size: 18px">Are you sure want to logout ?</h2>
  <br>
  <!-- <button mat-raised-button color="primary" >Confirm</button> &nbsp; &nbsp;
  <button mat-raised-button >Cancel</button> -->
  <div style = "display: flex; justify-content: space-between;">
    <button (click)="closeDialog()" class="btn v1206">Cancel</button>
    <button (click)="logout()" class="btn v2">Confirm</button>
  </div>
  
</div>

