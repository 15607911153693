<div>
  <div>
    <label *ngIf="action_type == 1" class="top-label">Add Manager details</label>
    <label *ngIf="action_type == 2" class="top-label">Update Manager details</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <div class="d-flex justify-content-center">
    <img class="mx-auto w-200 img-fluid" [src]="final_url">
  </div>
  <form #spaceForm="ngForm" (ngSubmit)="addOrUpdate()" class="space-form">
    <div class="d-flex mt-3">
      <label class="mr-5">Profile Photo</label>
      <input type="file" name="file" id="file" (change)="fileChangeEvent($event)" accept="image/jpeg, image/png">
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Name</mat-label>
          <input type="string" matInput placeholder="Name" name="name" [(ngModel)]="manager.name">
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Email</mat-label>
          <input type="string" matInput placeholder="Email" name="email" [(ngModel)]="manager.email">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Mobile</mat-label>
          <input type="string" matInput placeholder="Mobile" name="mobile" [(ngModel)]="manager.mobile">
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="space-small-form-inputs w-100" appearance="outline">
          <mat-label>Select Space</mat-label>
          <mat-select [(ngModel)]="manager.space_ids" name="space_id" multiple>
            <mat-option *ngFor="let space of spaces" [value]="space.id">
              {{space.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>



    <div class="row"></div>
    <mat-form-field class="space-small-form-inputs" appearance="outline" *ngIf="action_type == 2">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="manager.status" name="status">
        <mat-option *ngFor="let status of statusArray" [value]="status.id">
          {{status.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <button mat-raised-button color="accent" type="submit" [disabled]="!spaceForm.form.valid">Submit</button>
    </div>
  </form>
</div>