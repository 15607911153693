import { BrowserModule } from "@angular/platform-browser";
import { DEFAULT_CURRENCY_CODE, NgModule } from "@angular/core";

import { routes, navigatableComponents } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
// import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from "@angular/material/grid-list";
import { CdkAccordionModule } from "@angular/cdk/accordion";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import {
  DateAdapter,
  MatOptionModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { SpaceService } from "./services/space.service";
import { CsvDownloadFileService } from "./services/csv-download.service";
import { AppGlobals } from "./services/app-globals";
import { WorkerService } from "./services/worker.service";
import { UpdateWorkerDialog } from "./workers/update_worker.component";
import { LoaderService } from "./services/loader.service";
import { LoaderInterceptorService } from "./services/loader-interceptor.service";
import { ShowImageDialog } from "./add-space/show-image-dialog.component";
import { DeleteImageDialog } from "./add-space/delete-image-dialog.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MemberService } from "./services/member.service";
import { DeleteWorkerDialog } from "./workers/delete_worker.component";
import { ManagerService } from "./services/manager.service";
import { DeleteSpaceDialog } from "./spaces/delete-space-dialog.component";
import { QueriesService } from "./services/queries.service";
import { AuthenticationService } from "./services/authentication.service";
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LogoutDialog } from "./home/logout-dialog.component";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { UpdateManagerDialog } from "./managers/update-manager-dialog.component";
import { DeleteManagerDialog } from "./managers/delete-manager-dialog.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MaterialModule } from "./material.module";
import { MatChipsModule } from "@angular/material/chips";
import { TeamcabinService } from "./services/teamcabin.service";
import { GoogleMapsModule } from "@angular/google-maps";
// import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { SendMessageDialog } from "./leads/send-message-dialog.component";
import { AddLeadDialog } from "./leads/add-lead-dialog.component";
import { DeleteTeamcabinDialog } from "./teamcabins/delete-teamcabin.component";
import { DeleteMeetingRoomDialog } from "./meeting_rooms/delete-meetingroom.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { UpdateTeamcabinDialog } from "./teamcabins/add-update-teamcabin-dialog.component";
import { UpdateMeetingRoomDialog } from "./meeting_rooms/add-update-meetingroom-dialog.component";
import { MeetingRoomService } from "./services/meetingRoom.service";
import { FaqsComponent } from "./faqs/faqs.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectFilterModule } from "mat-select-filter";
import { FaqsService } from "./services/faqs.service";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { EmailPreviewComponent } from "./leads/email-preview/email-preview.component";
import { SanitizerUrlPipe } from "./pipes/img-pipe";

@NgModule({
  declarations: [
    SanitizerUrlPipe,
    AppComponent,
    SendMessageDialog,
    AddLeadDialog,
    UpdateManagerDialog,
    UpdateWorkerDialog,
    DeleteTeamcabinDialog,
    UpdateTeamcabinDialog,
    DeleteWorkerDialog,
    ShowImageDialog,
    // UploadImageDialog,
    DeleteMeetingRoomDialog,
    DeleteSpaceDialog,
    DeleteImageDialog,
    UpdateMeetingRoomDialog,
    DeleteManagerDialog,
    ...navigatableComponents,
    FaqsComponent,
    EmailPreviewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    HttpClientModule,
    GoogleMapsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatFormFieldModule,
    MatChipsModule,
    MatTabsModule,
    CKEditorModule,
    // UpdateManagerDialog,
    // DeleteManagerDialog,
    MatProgressBarModule,
    FormsModule,
    MatRadioModule,
    MatButtonModule,
    MatListModule,
    MaterialModule,
    MatDialogModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatGridListModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatSelectFilterModule,
    MatButtonToggleModule,
    RouterModule.forRoot(routes),
  ],
  providers: [
    FaqsService,
    AuthenticationService,
    LoaderService,
    MemberService,
    CsvDownloadFileService,
    MeetingRoomService,
    DeleteMeetingRoomDialog,
    SpaceService,
    AuthenticationService,
    LoaderService,
    // CompanyService,
    TeamcabinService,
    MemberService,
    WorkerService,
    // RatingService,
    // MembershipService,
    // PerformaInvoiceService,
    QueriesService,
    // InvoiceService,
    // RecurringInvoiceService,
    // TransactionService,
    // SpaceService,
    // BookingService,
    // ReceiptService,
    AppGlobals,
    // OverviewService,
    // VisitorService,
    // HelpdeskService,
    // ConversationService,
    // ResourceService,
    // PlanService,
    ManagerService,
    // MeetingRoomService,
    // RevenueService,
    // ContractService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'Rs. '
    },
  ],
  entryComponents: [
    SendMessageDialog,
    EmailPreviewComponent,
    AddLeadDialog,
    LogoutDialog,
    // LocationListBottomSheet,
    UpdateWorkerDialog,
    DeleteWorkerDialog,
    UpdateMeetingRoomDialog,
    ShowImageDialog,
    DeleteImageDialog,
    // UpdatePlanDialog,
    // UpdateSpaceDialog,
    UpdateManagerDialog,
    DeleteSpaceDialog,
    // UploadImageDialog,
    DeleteManagerDialog,
    UpdateTeamcabinDialog,
    DeleteTeamcabinDialog,
    // UpdateRatingDialog,
    // DeleteRatingDialog,
    // DeleteSpaceDialog
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
