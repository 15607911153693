import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpaceService } from '../services/space.service';
import { LoaderService } from '../services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Manager {
   id: number
   name: string;
   email: string;
   mobile: string;
   status: number;
   space_id: number;
}

@Component({
   selector: 'delete-space',
   templateUrl: './delete-space-dialog.component.html',
   styleUrls: ['./delete-space-dialog.component.css']
})

export class DeleteSpaceDialog {
   public ref;
   public selected_space;
   public message = '';

   constructor(
      public dialogRef: MatDialogRef<any>,
      private spaceService: SpaceService,
      public snackBar: MatSnackBar,
      private loaderService: LoaderService
   ) { }

   openSnackBar(message: string, action: string) {
      this.loaderService.displayLoader(false);
      this.snackBar.open(message, action, {
         duration: 5000,
      });
   }

   closeDialog(options) {
      this.ref.close(options);
   }

   delete() {
      this.spaceService.delete(this.selected_space.id)
         .then(data => {
            if (data.success) {
               this.message = data.message;
               this.closeDialog({ success: true, message: data.message });
               this.openSnackBar(this.message, "Dismiss");
            }
         })
         .catch(error => {
            this.message = "Something went wrong";
            this.openSnackBar(this.message, "Dismiss");
            console.log(error);
         })
   }

}