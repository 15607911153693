import { Component, OnInit, NgZone, ChangeDetectorRef, Inject } from '@angular/core';
import { SpaceService } from '../services/space.service';
import { LoaderService } from '../services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { GlobalVariables } from '../global/global-variables';
import { SendProposalOptions } from '../models/send-proposal-options';
import { WorkerService } from '../services/worker.service';
import { SendProposal } from '../models/send-proposal';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { ManagerService } from '../services/manager.service';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from 'jwt-decode';
import { CityService } from '../services/city.service';

@Component({
  selector: '',
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.css']
})

export class SendMessageDialog implements OnInit {

  constructor(
    private spaceService: SpaceService,
    private ngZone: NgZone,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private workerService: WorkerService,
    private dialog: MatDialog,
    private managerService: ManagerService,
    private cityService: CityService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public ref: MatDialogRef<SendMessageDialog>,
  ) { }
  public selected_lead;
  public locality_type;
  public geocoder = new google.maps.Geocoder();
  public lat;
  public params;
  public space_ids = [];
  public spaces_list_with_custom_prices = [];
  public long;
  public center;
  public spaces_list = [];
  public filtered_spaces_list: Observable<any[]>;
  public space_selected = new FormControl('');
  public optionsForm: FormGroup;
  public facilities_list = GlobalVariables.facilities;
  public send_proposal_options: SendProposalOptions[] = [];
  public btn_txt = 'Add';
  public show_option_form = true;
  public workers: any[];
  public send_proposal = new SendProposal();
  private inclusions: string[] = [];
  public cities_list: { id: number, name: string }[] = [];
  public filtered_cities_list: Observable<{ id: number, name: string }[]>;
  public city_slected = new FormControl('');
  public location_list: { id: number, location_name }[] = [];
  public filtered_locations_list: Observable<{ id: number, location_name: string }[]>;
  public location_slected = new FormControl('');
  public _rough_control = new FormControl('');
  public why_image_default: any = `https://ci5.googleusercontent.com/proxy/X5w1P8KWK1oC-t3N2f45vqTc4NQYSoM-m-ZpX9rrVkvS9WBnzBw0s1_yGsNxtXrAY8BXpKeNCkY4wBD4jT8Jok3L2DY1rO3z7c4MYioDRCPzf6GBv2PA1UeVZsBBJuBR-D7N_4x6J48jOwjaBn8TBOLmghjUcqx-f3aXDywnifKAr5H2eL_-XIuxT_VvW16MZeSlyA_l3ZwHOwPRz2s6zTq13Ei6fBpUqffE5WA=s0-d-e1-ft#https://615830bd01.imgdist.com/public/users/Integrators/BeeProAgency/992614_977343/FLEXO%20Client%20Proposal%20Template%20%281200%20%C3%97%20848px%29.png`;
  public steps_image_default: any = `https://ci3.googleusercontent.com/proxy/PDuEb71Ure6xWGzk9YhUbfplUo_fo0LKXVbMz8WI8Rt7DogLZK5GhOAOvTDDwUQg_h8eKCpyVP40B-CRNq5e8cityv0hZ7lqCnrilE2K2IbhQ_Nr9slOlIOqyKu8kOVzUcAnvbOoj0bZ3jgxLzVTDcHNf17V4KjlwtKxDHTysapNCnHHeV1cPC4URjEeDtMwdfTkQH2Q6cJOHyBdmwax5wGFNUC_4V40QXYW9AZdMZvoGuwNoJ0Q2HJkPQUwQCqXu7ATDOMVsbj4Yis=s0-d-e1-ft#https://615830bd01.imgdist.com/public/users/Integrators/BeeProAgency/992614_977343/FLEXO%20Client%20Proposal%20Template%20%281200%20%C3%97%20848px%29%20%281200%20%C3%97%20800px%29.png`;
  public image_why: any;
  public image_steps: any;
  public is_admin: any = 0;
  ngOnInit() {
    if (this.locality_type == 3) {
      this.spaces_list.push(this.selected_lead.id);
    }
    this.filterSpace();
    this.filterCity();
    this.createOptionsForm();
    setTimeout(() => {
      this.getUserDetails();
    }, 300);
    this.getCities();
    setTimeout(() => {
      let payload: any = jwt_decode(localStorage.getItem('token'));
      this.send_proposal.signature = payload.user_id.toString();
      this.is_admin = payload.is_admin;
    }, 10);
    this.image_why = this.why_image_default;
    this.image_steps = this.steps_image_default;
    this.send_proposal.to = this._data?.email;
    this.send_proposal.receiver_name = this._data?.name;
  }

  public filterSpace() {
    this.filtered_spaces_list = this.space_selected.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSpace(value))
    );
  }

  public filterCity() {
    this.filtered_cities_list = this.city_slected.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCity(value))
    )
  }

  public filterLocation() {
    this.filtered_locations_list = this.location_slected.valueChanges.pipe(
      startWith(''),
      map(value => this._filterLocation(value))
    )
  }

  private _filterSpace(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.spaces_list.filter(option => option.actual_name.toLowerCase().includes(filterValue.toLowerCase()));
  }

  private _filterLocation(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.location_list.filter(option => option.location_name.toLowerCase().includes(filterValue.toLowerCase()));
  }

  private _filterCity(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.cities_list.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
  }

  ngAfterViewInit() {
    // if (this.locality_type == 1) {
    //   let place = this.selected_lead.location_name + '-' + this.selected_lead.city_name;
    //   this.geocode(place);
    // } else {
    //   this.geocode(this.selected_lead.city_name);
    // }

    // this.managerService.getSpaceDetails()
    //   .then((data) => {
    //     console.log(data);
    //   })
  }

  geocode = (place) => {
    this.geocoder.geocode({ 'address': `${place}`, }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let location = results[0].geometry.location;
        this.lat = location.lat();
        this.long = location.lng();
        if (this.locality_type == 1) {
          // this.populateAreaSpaces();
        } else {
          // this.getSpacesByCity(this.selected_lead.city_name);
        }
      }
    })
  }

  // getSpacesByCity(place) {
  //   let city_name = place;
  //   let city_params = {
  //     city_name: null,
  //     city_lat: null,
  //     city_long: null,
  //     resource_type: this.selected_lead.resource_type
  //   };
  //   city_params.city_name = city_name;
  //   this.ngZone.run(() => {
  //     this.spaceService.getSpacesByCityIdForProposals(city_params)
  //       .then(res => {
  //         this.spaces_list = Object.assign([], res.data);

  //       })
  //   })

  // }

  // populateAreaSpaces = () => {
  //   let params = {};
  //   let area_long = this.long
  //   let area_lat = this.lat
  //   params = {
  //     ...params, area_lat, area_long, resource_type: this.selected_lead.resource_type
  //   };
  //   this.params = Object.assign({}, params);
  //   this.ngZone.run(() => {
  //     this.spaceService.getSpacesByAreaForProposals(params)
  //       .then(res => {
  //         this.spaces_list = Object.assign([], res.data);
  //         this.ngZone.run(() => this.cdRef.detectChanges())
  //       })
  //   })
  // }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, { duration: 3000 });
  }

  public createCustomPriceList(space) {
    if (this.spaces_list_with_custom_prices.find(obj => obj.id == space.id)) {
      this.spaces_list_with_custom_prices = this.spaces_list_with_custom_prices.filter(item => item.id != space.id);
    } else {
      this.spaces_list_with_custom_prices.push({
        id: space.id,
        name: space.name,
        custom_price: null,
        actual_name: space.actual_name
      });
    }
  }

  public setSpaceIds(space_id, event) {
    const index = this.space_ids.indexOf(space_id);
    if (event.currentTarget.checked) {
      this.space_ids.push(space_id);
    } else {
      if (index > -1) {
        this.space_ids.splice(index, 1);
      }
    }
  }

  selectCity(city: any) {
    this.location_list = [];
    this.filtered_locations_list = null;
    this.spaces_list = [];
    this.filtered_spaces_list = null;
    this.location_slected.patchValue('');
    this.getSpacesByCityId(city.id);
  }

  selectLocation(location: any) {
    var city = this.cities_list.filter(m => m.name === this.city_slected.value);
    this.getSpacesByLocation(city[0].id, location.location_name);
  }

  resetLocation() {
    var city = this.cities_list.filter(m => m.name === this.city_slected.value);
    this.getSpacesByCityId(city[0].id);
  }

  getSpaceById(space: any) {
    this.space_ids.push(space.id);
    this.optionsForm.get('id').patchValue(space.id);
    this.optionsForm.get('actual_name').patchValue(space.actual_name);
    this.spaceService.getSpaceDetails(space.id).then(
      res => {
        this.optionsForm.get('images').patchValue(res.data.images);
        this.optionsForm.get('location_name').patchValue(res.data.location_name);
        this.optionsForm.get('landmark').patchValue(res.data.landmark);
        var name = res.data.actual_name.replace(/ /g, "-");
        var location = res.data.location_name.replace(/ /g, "-");
        var link = name + '-' + location + '-' + res.data.id;
        this.optionsForm.get('link_name').patchValue(link.toLowerCase());
        var city = this.cities_list.filter(m => m.id === res.data.city_id);
        this.optionsForm.get('city').patchValue(city[0].name);
      }
    )
  }

  addOption() {
    if (this.send_proposal_options.length >= 10) {
      this.snackBar.open("Maximum 10 options allowed!", 'Dismiss', { duration: 3000 })
    } else {
      if (this.optionsForm.invalid) {
        this.snackBar.open("Please fill all the fields correctly!", 'Dismiss', { duration: 3000 });
      } else {
        if (this.optionsForm.value.inclusion_1) {
          this.inclusions.push(this.optionsForm.value.inclusion_1);
        }
        if (this.optionsForm.value.inclusion_2) {
          this.inclusions.push(this.optionsForm.value.inclusion_2);
        }
        if (this.optionsForm.value.inclusion_3) {
          this.inclusions.push(this.optionsForm.value.inclusion_3);
        }
        if (this.optionsForm.value.inclusion_4) {
          this.inclusions.push(this.optionsForm.value.inclusion_4);
        }
        if (this.optionsForm.value.inclusion_5) {
          this.inclusions.push(this.optionsForm.value.inclusion_5);
        }
        if (this.optionsForm.value.inclusion_6) {
          this.inclusions.push(this.optionsForm.value.inclusion_6);
        }
        this.optionsForm.get('inclusions').patchValue(this.inclusions);
        this.send_proposal_options.push(this.optionsForm.value);
        this.optionsForm.reset({
          quoted_price_unit: 'month',
          total_unit: 'month',
          mask_name: true,
          not_add_link: true,
          inclusion_1: 'Electricity',
          inclusion_2: 'AC',
          inclusion_3: 'High Speed Wi-Fi',
          inclusion_4: 'Tea/Coffee',
          inclusion_5: 'House-Keeping',
          inclusion_6: 'Plug N Play',
          negotiable: true,
        });
        this.space_selected.patchValue('');
        this.btn_txt = 'Add';
        this.inclusions = [];
        this.snackBar.open('Option add successfully!', 'Dismiss', { duration: 3000 });
        if (this.send_proposal_options.length >= 1) {
          this.show_option_form = false;
        }
      }
    }
  }

  createOptionsForm() {
    this.optionsForm = this._fb.group({
      id: [null, [Validators.required]],
      actual_name: ['', [Validators.required]],
      mask_name: [true, [Validators.required]],
      inclusions: [[]],
      inclusion_1: ['Electricity'],
      inclusion_2: ['AC'],
      inclusion_3: ['High Speed Wi-Fi'],
      inclusion_4: ['Tea/Coffee'],
      inclusion_5: ['House-Keeping'],
      inclusion_6: ['Plug N Play'],
      not_add_link: [true, [Validators.required]],
      space_type: ['', [Validators.required]],
      quoted_price_per_seat: [null, [Validators.required]],
      quoted_price_unit: ['month', [Validators.required]],
      total_billable_seats: [null, [Validators.required]],
      total: [null, [Validators.required]],
      total_unit: ['month', [Validators.required]],
      images: [[]],
      location_name: [''],
      landmark: [''],
      link_name: [''],
      city: [''],
      negotiable: [true],
    })
  }

  sendMail = () => {
    if (this.validate()) {
      this.send_proposal.options = this.send_proposal_options;
      this.spaces_list_with_custom_prices.forEach(element => {
        delete element.name;
      })
      if (this.send_proposal.hide_why_image) {
        this.send_proposal.why_image = undefined;
        this.send_proposal.why_image_link = undefined;
      }
      if (this.send_proposal.hide_steps_image) {
        this.send_proposal.steps_image = undefined;
        this.send_proposal.steps_image_link = undefined;
      }
      if (this.send_proposal.why_image === undefined && !this.send_proposal.hide_why_image) {
        this.send_proposal.why_image_link = this.why_image_default;
      } else {
        this.send_proposal.why_image_link = undefined;
      }
      if (this.send_proposal.steps_image === undefined && !this.send_proposal.hide_steps_image) {
        this.send_proposal.steps_image_link = this.steps_image_default;
      } else {
        this.send_proposal.steps_image_link = undefined;
      }
      setTimeout(() => {
        let formData: any = new FormData();
        formData.append('options', JSON.stringify(this.send_proposal.options));
        formData.append('to', this.send_proposal.to);
        formData.append('signature', this.send_proposal.signature);
        formData.append('subject', this.send_proposal.subject);
        formData.append('heading', this.send_proposal.heading);
        formData.append('description', this.send_proposal.description);
        formData.append('text', this.send_proposal.text);
        formData.append('lead_id', this._data == null ? 0 : this._data?.id);
        formData.append('resource_type', this.send_proposal.resource_type);
        formData.append('attach_pdf_proposal', this.send_proposal.attach_pdf_proposal);
        formData.append('why_image', this.send_proposal.why_image);
        formData.append('steps_image', this.send_proposal.steps_image);
        formData.append('why_image_link', this.send_proposal.why_image_link);
        formData.append('steps_image_link', this.send_proposal.steps_image_link);
        formData.append('hide_why_image', this.send_proposal.hide_why_image);
        formData.append('hide_steps_image', this.send_proposal.hide_steps_image);
        formData.append('advantage_text', this.send_proposal.advantage_text);
        formData.append('manager', JSON.stringify(this.send_proposal.manager));
        this._openSnackBar('Proposal email is is progress', 'Dismiss');
        this.spaceService.sendMailForLead(formData)
          .then(data => {
            if (data && data.success) {
              this._openSnackBar('Sent successfully', 'Dismiss')
            }
          })
          .catch(err => {
            throw err;
          })
      }, 100);
    }
  }

  onspaceclick = () => { }
  closeDialog(options) {
    this.ref.close(options);
  }

  removeOption(option: SendProposalOptions) {
    const index = this.send_proposal_options.indexOf(option);
    if (index >= 0) {
      this.send_proposal_options.splice(index, 1);
    }
    this.snackBar.open('Option remove successfully!', 'Dismiss', { duration: 3000 });
  }

  updateOption(option: SendProposalOptions) {
    this.optionsForm.patchValue(option);
    this.space_selected.patchValue(option.actual_name);
    this.btn_txt = 'Update';
    const index = this.send_proposal_options.indexOf(option);
    if (index >= 0) {
      this.send_proposal_options.splice(index, 1);
    }
    this.show_option_form = true;
  }

  selectWorker(worker: any) {
    this.send_proposal.manager = worker;
    this.send_proposal.manager!.image = worker?.profile_image;
  }

  showPreview() {
    if (this.validate()) {
      this.send_proposal.options = this.send_proposal_options;
      let config = new MatDialogConfig();
      config.data = this.send_proposal;
      config.minWidth = '90vw';
      config.minHeight = '90vh';
      this.dialog.open(EmailPreviewComponent, config);
    }
  }

  validate() {
    if (this.send_proposal_options.length === 0) {
      this.snackBar.open('Please add atleast one option!', 'Dismiss', { duration: 3000 });
    } else if (this.send_proposal.manager === undefined && this.is_admin == 1) {
      this.snackBar.open('Please select advisor!', 'Dismiss', { duration: 3000 });
    } else if (!this.send_proposal.heading) {
      this.snackBar.open('Please add heading!', 'Dismiss', { duration: 3000 });
    } else if (!this.send_proposal.description) {
      this.snackBar.open('Please add description!', 'Dismiss', { duration: 3000 })
    } else {
      return true;
    }
  }

  getUserDetails() {
    this.managerService.getAllDetails()
      .then(data => {
        this.workers = data.data;
        var manager = this.workers.find(m => m.id == this.send_proposal.signature);
        console.log(manager);
        setTimeout(() => {
          if (this.is_admin == 0) {
            this.send_proposal.manager.id = manager.id;
            this.send_proposal.manager.name = manager.name;
            this.send_proposal.manager.email = manager.email;
            this.send_proposal.manager.mobile = manager.mobile;
            this.send_proposal.manager.image = manager.profile_image;
            this.send_proposal.manager.spaces_data = manager.spaces_data;
          }
        }, 1000);
      })
      .catch(err => {
        throw err;
      })
  }

  calculateTotal() {
    let price = this.optionsForm.value.quoted_price_per_seat;
    let seats = this.optionsForm.value.total_billable_seats;
    if (price !== null && seats !== null) {
      this.optionsForm.get('total').patchValue(+price * +seats);
    }
  }

  getSpacesByCityId(id: number) {
    this.spaceService.getSpacesByCity(id)
      .subscribe((r: any) => {
        this.spaces_list = r;
        this.location_list = Array.from(this.spaces_list.reduce((m, t) => m.set(t.location_name, t), new Map()).values());
      })
  }

  getSpacesByLocation(city_id: number, location: string) {
    this.spaceService.getSpacesByCityAndLocation(city_id, location)
      .subscribe((r: any) => {
        var sort_list = r.sort(function (a, b) { return a.actual_name.localeCompare(b.actual_name) });
        var filtered_list = sort_list.filter(m => m.status == 1);
        this.spaces_list = filtered_list;
      })
  }

  getCities() {
    this.cityService.getCities()
      .then(data => {
        this.cities_list = data.data;
      })
      .catch(err => {
        throw err;
      })
  }

  whyFileChangeEvent(fileInput) {
    if (fileInput) {
      const file: File = fileInput.target.files[0];
      this.send_proposal.why_image = file;
      const reader = new FileReader();
      reader.onload = e => this.image_why = reader.result;
      reader.readAsDataURL(file);
    }
  }

  stepsFileChangeEvent(fileInput) {
    if (fileInput) {
      const file: File = fileInput.target.files[0];
      this.send_proposal.steps_image = file;
      const reader = new FileReader();
      reader.onload = e => this.image_steps = reader.result;
      reader.readAsDataURL(file);
    }
  }

  resetWhyImage() {
    this.image_why = this.why_image_default;
    this.send_proposal.why_image_link = this.why_image_default;
    this.send_proposal.why_image = undefined;
  }

  resetStepsImage() {
    this.image_steps = this.steps_image_default;
    this.send_proposal.steps_image_link = this.steps_image_default;
    this.send_proposal.steps_image = undefined;
  }
}

