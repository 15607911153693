import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class MeetingRoomService {

  public selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this.selectedLocationId = id);
  }

  private base_url = 'api/v1/admin';
  private getAllUrl = environment.apiUrl + this.base_url + '/getAllMeetingRooms';
  private getAllDetailsUrl = environment.apiUrl + this.base_url + '/allDetails';
  private addDetailsUrl = environment.apiUrl + this.base_url + '/add_meeting_room';
  private updateDetailsUrl = environment.apiUrl + this.base_url + '/update_meeting_room';
  private deleteDetailsUrl = environment.apiUrl + this.base_url + '/delete_meeting_room';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  private _options = {
    headers: this.headers,
    withCredentials: true
  };

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAllDetails(location_id): Promise<any> {
    let location_condition = location_id && location_id != 0 ? `?location_id=${location_id}` : '';

    return this.http.get(this.getAllDetailsUrl + location_condition,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addDetails(details): Promise<any> {
    return this.http.post(this.addDetailsUrl, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateDetails(meetingRoom_id, meetingRoom_details): Promise<any> {
    return this.http.put(this.updateDetailsUrl + '/' + meetingRoom_id, JSON.stringify(meetingRoom_details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  getAllMeetingRooms(): Promise<any> {
    return this.http.get(this.getAllUrl,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  deleteDetails(meetingRoom_id): Promise<any> {
    return this.http.delete(this.deleteDetailsUrl + '/' + meetingRoom_id, { headers: this.headers, withCredentials: true })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

}
