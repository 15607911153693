import { Routes } from "@angular/router";
import { AddSpaceComponent } from "./add-space/add-space.component";
import { SpacesComponent } from "./spaces/spaces.component";
import { ManagersComponent } from "./managers/manager.component";
import { WorkersComponent } from "./workers/workers.component";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { LeadsComponent } from "./leads/leads.component";
import { QueriesComponent } from "./queries/queries.component";
import { SpaceDetailComponent } from "./space-detail/space-detail.component";
import { TeamCabinComponent } from "./teamcabins/teamcabin.component";
import { MeetingRoomComponent } from "./meeting_rooms/meetingroom.component";
import { FaqsComponent } from "./faqs/faqs.component";
export const routes: Routes = [

  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    component: HomeComponent,
    children: [
      { path: "", redirectTo: "spaces", pathMatch: "full" },
      { path: "managers", component: ManagersComponent },
      { path: "workers", component: WorkersComponent },
      { path: "spaces", component: SpacesComponent },
      { path: "add-space", component: AddSpaceComponent },
      { path: "update-space/:id", component: AddSpaceComponent },
      { path: "leads", component: LeadsComponent },
      { path: "queries", component: QueriesComponent },
      { path: "space-detail/:id", component: SpaceDetailComponent },
      { path: "teamcabins", component: TeamCabinComponent },
      { path: "meeting-rooms", component: MeetingRoomComponent },
      { path: "faqs", component: FaqsComponent },
    ],
  },
];

export const navigatableComponents = [
  LoginComponent,
  HomeComponent,
  SpacesComponent,
  ManagersComponent,
  QueriesComponent,
  SpaceDetailComponent,
  LeadsComponent,
  WorkersComponent,
  AddSpaceComponent,
  TeamCabinComponent,
  MeetingRoomComponent,
];
