import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
import { TeamcabinService } from '../services/teamcabin.service';
import { GlobalVariables } from '../global/global-variables';
// import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { FilterPipe } from '../filter.pipe';
// import {RatingModule} from "ngx-rating";
import { UpdateTeamcabinDialog } from './add-update-teamcabin-dialog.component';
import { DeleteTeamcabinDialog } from './delete-teamcabin.component';

export class Teamcabin {
  id: number;
  name: string;
  space_id: number;
  price: number;
  capacity: number;
  space_name: string;
}

@Component({
  selector: 'app-teamcabins',
  templateUrl: './teamcabin.component.html',
  styleUrls: ['./teamcabin.component.css']
})
export class TeamCabinComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private _teamcabinservice: TeamcabinService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public teamcabin_dialog: MatDialog,
    public teamcabin_viewContainerRef: ViewContainerRef,
    private _appGlobals: AppGlobals,
    public snackBar: MatSnackBar
  ) { }
  teamcabin_dialogRef: MatDialogRef<any>
  public teamcabin_data;
  public TeamcabinsDataSource;
  public search_text: string;
  public website_base_url: string = 'http://localhost:4200';
  selection = new SelectionModel<Teamcabin>(true, []);
  teamcabinDisplayedColumns: string[] = ['id', 'name', 'is_active', 'price', 'capacity', 'space_name'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.populateTeamcabins();
  }

  public populateTeamcabins() {
    this._teamcabinservice.getAll()
      .then(res => {
        if (res.success) {
          this.teamcabin_data = Object.assign([], res.data);
          this.TeamcabinsDataSource = new MatTableDataSource(this.teamcabin_data)
          this.TeamcabinsDataSource.sort = this.sort;
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  applyFilter(filterValue: string) {
    this.TeamcabinsDataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearSearchInput() {
    this.search_text = '';
  }

  openEditTeamcabinDialog(teamcabin_obj, action_type) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.teamcabin_viewContainerRef;
    config.disableClose = true;

    this.teamcabin_dialogRef = this.teamcabin_dialog.open(UpdateTeamcabinDialog, config);
    this.teamcabin_dialogRef.componentInstance.ref = this.teamcabin_dialogRef;
    this.teamcabin_dialogRef.componentInstance.selected_teamcabin = teamcabin_obj;
    this.teamcabin_dialogRef.componentInstance.action_type = action_type;
    this.teamcabin_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateTeamcabins();
      }
      this.teamcabin_dialogRef = null;
    });
  }

  openDeleteTeamcabinDialog(teamcabin_obj) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.teamcabin_viewContainerRef;
    config.disableClose = true;

    this.teamcabin_dialogRef = this.teamcabin_dialog.open(DeleteTeamcabinDialog, config);
    this.teamcabin_dialogRef.componentInstance.ref = this.teamcabin_dialogRef;
    this.teamcabin_dialogRef.componentInstance.selected_teamcabin = teamcabin_obj;
    this.teamcabin_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateTeamcabins();
      }
      this.teamcabin_dialogRef = null;
    });
  }
}
