import { Component, ViewContainerRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})


export class AppComponent {
  // title = 'flexo-admin';
}
