import {
  Component,
  ViewContainerRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { LoaderService } from "../services/loader.service";
import { AuthenticationService } from "../services/authentication.service";
// import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
// import {LocationListBottomSheet} from './location-list-bottom-sheet.component';
import { AppGlobals } from "../services/app-globals";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";
import { mapTo } from "rxjs/operators";
import { Observable, fromEvent, merge, of } from "rxjs";
import { ManagerService } from "../services/manager.service";
import { LogoutDialog } from "./logout-dialog.component";
import * as _ from "lodash";
import { SendMessageDialog } from "../leads/send-message-dialog.component";
@Component({
  selector: "app-root",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent {
  loadAPI: Promise<any>;
  public objLoaderStatus: boolean = false;
  public isMobile = false;
  public space_locations;
  public space_details;
  public location_ids;
  public list_locations;
  public selectedLocationId;
  public space_ids;
  public spaces_data;
  public dialogRef: MatDialogRef<any>;
  public is_admin;
  public loading: boolean;
  constructor(
    private router: Router,
    // private bottomSheet: MatBottomSheet,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _appGlobals: AppGlobals,
    private viewContainerRef: ViewContainerRef,
    private managerService: ManagerService,
    
  ) {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
    if (window.innerWidth < 600) {
      this.isMobile = true;
    }
  }
  ngOnInit() {
    // this.loaderService.loaderStatus.subscribe((val: boolean) => {
    //   this.objLoaderStatus = val;
    // });
    // this.authenticationService.validateSession().then((res) => {
    //   if (!res.verified) {
    //     this.router.navigate(["login"]);
    //   }
    //   else {
    //     this._getSpaceDetails();
    //   }
    // });
  }
  private _getSpaceDetails() {
    this.managerService.getSpaceDetails().then((res) => {
      this.space_ids = res.data.space_ids;
      this.spaces_data = res.data.spaces_data;
      this.is_admin = res.data.is_admin;
      // this._appGlobals.setSpaceIDS(this.space_ids);
      this._appGlobals.setIsAdmin(this.is_admin);
      this._appGlobals.setSpacesData(this.spaces_data);
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute("src") != null &&
        scripts[i].getAttribute("src").includes("loader")
      ) {
        isFound = true;
      }
    }
    if (!isFound) {
      var dynamicScripts = ["assets/js/dashboard.js"];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    }
  }

  openLogoutDialog() {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    config.disableClose = true;
    this.dialogRef = this.dialog.open(LogoutDialog, config);
    this.dialogRef.afterClosed().subscribe((result) => {
      result.success ? this.router.navigate(["login"]) : "";
      this.dialogRef = null;
    });
  }

  public sendProposalDialog() {
    let config = new MatDialogConfig();
    config.disableClose = true;
    config.minWidth = '95vw';
    config.minHeight = '95vh';
    const dialog = this.dialog.open(SendMessageDialog, config);
  }
  
  title = "flexo-admin";
}
