import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
// import {MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar, MatSort, MatTableDataSource, MatOption} from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
import { WorkerService } from '../services/worker.service';
import { UpdateWorkerDialog } from './update_worker.component';
import { DeleteWorkerDialog } from './delete_worker.component';
import { GlobalVariables } from '../global/global-variables';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';

export interface Worker {
  s_no: number;
  alternate_id: string;
  entity_alternate_id: string;
  total_amount: number;
  entity_name: string;
  entity_type: number;
  status: number;
  paid_status: number;
  send_status: number;
  tracked_with_bhaifi: number;
}

@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.css']
})

export class WorkersComponent implements OnInit {

  public selected_status: number;
  public selected_space: number;
  public list_space;
  public search_string;
  public date_display;
  public date;
  public worker_details;
  constructor(
    private loaderService: LoaderService,
    private _workerservice: WorkerService,
    public dialog: MatDialog,
    public worker_dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public confirm_dialog: MatDialog,
    public worker_viewContainerRef: ViewContainerRef,
    public confirm_viewContainerRef: ViewContainerRef,
    private _appGlobals: AppGlobals,
    public snackBar: MatSnackBar
  ) { }

  dialogRef: MatDialogRef<any>
  worker_dialogRef: MatDialogRef<any>
  confirm_dialogRef: MatDialogRef<any>

  private _workers_data: Worker[];
  public selected_workers_data: Worker[] = [];
  public workersDataSource;

  selection = new SelectionModel<Worker>(true, []);
  workersDisplayedColumns: string[] = ['id', 'name', 'email', 'action'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.selected_status = 0;
    this.populateWorkers();

  }

  public populateWorkers() {
    this._workerservice.getAll()
      .then(res => {
        if (res.success) {
          this._workers_data = Object.assign([], res.data);
          this.selected_workers_data = this._workers_data;
          this.workersDataSource = new MatTableDataSource(this.selected_workers_data)
          this.workersDataSource.sort = this.sort;
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  public changeStatus(status: number) {
    this.selected_status = status;
    switch (status) {
      case 0:
        this.selected_workers_data = Object.assign([], this._workers_data);
        break;
      case 1:
        this.selected_workers_data = Object.assign([], _.filter(this._workers_data, ['status', 1]));
        break;
      case 2:
        this.selected_workers_data = Object.assign([], _.filter(this._workers_data, ['status', 2]));
        break;
      default:
        this.selected_workers_data = Object.assign([], this._workers_data);
    }
    this.workersDataSource = new MatTableDataSource(this.selected_workers_data)
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  applyFilter(filterValue: string) {
    this.workersDataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearSearchInput() {
    this.search_string = '';
    this.applyFilter('');
  }
  openEditWorkerDialog(worker_obj) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.worker_viewContainerRef;
    config.disableClose = true;

    this.worker_dialogRef = this.worker_dialog.open(UpdateWorkerDialog, config);
    this.worker_dialogRef.componentInstance.ref = this.worker_dialogRef;
    this.worker_dialogRef.componentInstance.selected_worker = worker_obj;
    this.worker_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateWorkers();
      }
      this.worker_dialogRef = null;
    });
  }

  openSettingsWorkerDialog(worker_obj) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.worker_viewContainerRef;
    config.disableClose = true;

    this.worker_dialogRef = this.worker_dialog.open(DeleteWorkerDialog, config);
    this.worker_dialogRef.componentInstance.ref = this.worker_dialogRef;
    this.worker_dialogRef.componentInstance.selected_worker = worker_obj;
    this.worker_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateWorkers();
      }
      this.worker_dialogRef = null;
    });
  }
}