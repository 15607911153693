import { Component, ViewChild, ViewContainerRef, ElementRef, Inject, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoaderService } from '../services/loader.service';
import { AppGlobals } from '../services/app-globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MemberService } from '../services/member.service';
declare var geolocation: any;
declare var google: any;

@Component({
  selector: 'add-lead',
  templateUrl: './add-lead-dialog.component.html',
  styleUrls: ['./add-lead-dialog.component.css']
})

export class AddLeadDialog {
  public ref;
  public space_type;
  public booking_date_time;
  public company_name;
  public autocomplete_city_name = null;
  public autocomplete_location_name = null;
  public no_of_persons = 0;
  public name;
  public email;
  public mobile;
  public is_select_date_time_visible = false;
  public resource_types = GlobalVariables.resource_types;
  @ViewChild('autocomplete', { static: false }) autocompleteElement: ElementRef;
  public autocomplete: google.maps.places.Autocomplete;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private _memberService: MemberService,
    private ngZone: NgZone,
    private _appGlobals: AppGlobals,
  ) {
  }
  ngOnInit() {
  }

  onResourceTypeSelection(resource_type) {
    this.space_type = resource_type;
    if (resource_type == 6 || resource_type == 7) {
      this.is_select_date_time_visible = true;
    } else {
      this.is_select_date_time_visible = false;
    }
  }

  public initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteElement.nativeElement, { types: ['geocode'] });

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        let place_data = this.autocomplete && this.autocomplete.getPlace() || null;
        if (place_data.address_components[0].types.includes("sublocality")) {
          this.autocomplete_location_name = place_data.address_components[0].long_name;
        }
        for (let i = 0; i < place_data.address_components.length; i++) {
          if (place_data.address_components[i].types.includes("locality")) {
            this.autocomplete_city_name = place_data.address_components[i].long_name;
          }
        }
        console.log(this.autocomplete_city_name, this.autocomplete_location_name, 'klj');

      })
    })
    this.autocomplete.setFields(['address_component', 'geometry']);
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  setNoOfPeople(no_of_people) {
    this.no_of_persons = no_of_people;
  }

  submitForm() {
    this._memberService.addLead({
      location_name: this.autocomplete_location_name, type: this.space_type, persons: this.no_of_persons, company_name: this.company_name,
      city_name: this.autocomplete_city_name, booking_date_time: this.booking_date_time, mobile: this.mobile, email: this.email, name: this.name
    })
      .then(res => {
        if (res && res.success) {
          this.openSnackBar('Lead Added Successfully', 'Dismiss');
          this.closeDialog(res);
        }
      })
      .catch(error => {
        this.openSnackBar("Some error occured", "Error");
      })
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  closeDialog(options) {
    this.ref.close(options);
  }
}
