import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';

@Injectable()
export class TeamcabinService {

  private _selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this._selectedLocationId = id);
  }
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private base_url = 'api/v1/admin';
  private getAllUrl = environment.apiUrl + this.base_url + '/getAllTeamcabins';
  private addDetailsUrl = environment.apiUrl + this.base_url + '/add_teamcabin';
  private deleteDetailsUrl = environment.apiUrl + this.base_url + '/delete_teamcabin';
  private updateDetailsUrl = environment.apiUrl + this.base_url + '/update_teamcabin';

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  };

  private _options = {
    headers: this.headers,
    withCredentials: true
  };
  getAll(): Promise<any> {

    return this.http.get(this.getAllUrl,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  addDetails(details): Promise<any> {
    details.type = 0;
    return this.http.post(this.addDetailsUrl, JSON.stringify(details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  updateDetails(teamcabin_id, teamcabin_details): Promise<any> {
    return this.http.put(this.updateDetailsUrl + '/' + teamcabin_id, JSON.stringify(teamcabin_details), this._options)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  delete(teamcabin_id): Promise<any> {
    return this.http.delete(this.deleteDetailsUrl + '/' + teamcabin_id, { headers: this.headers, withCredentials: true })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }
}