<div class="page-wrapper">


  <!--Dashboard content Wrapper starts-->
  <div class="dash-content-wrap">
    <!--Dashboard breadcrumb starts-->
    <div class="dash-breadcrumb">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="dash-breadcrumb-content">
              <div class="dash-breadcrumb-left">
                <div class="breadcrumb-menu text-right sm-left">
                  <p class="list-address">Showing <b>{{selected_workers_data?.length}}</b> results</p>
                </div>
              </div>
              <!-- <button class="btn v3" (click)="openEditManagerDialog(null, 1)"><i class="ion-plus-round"></i>Add Manager
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dashboard breadcrumb ends-->
    <!--Dashboard content starts-->
    <div class="dash-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="invoice-panel">
              <div class="act-title">
                <h5><i style="align-self: center;" class="material-icons">person</i> Workers</h5>
              </div>
              <div class="invoice-body">
                <div class="table-responsive">
                  <table mat-table [dataSource]="workersDataSource" matSort class="member-table">
                    <ng-container matColumnDef="id">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                      <td class="table-cell" mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                      <td class="table-cell" mat-cell *matCellDef="let element">
                        <div style="display:flex; align-items: center;">
                          <!-- <img *ngIf="element.profile_image" src="{{element.profile_image}}">
                          <img *ngIf="!element.profile_image" src="assets/img/default-profile.png">&nbsp;&nbsp; -->
                          {{element.name}}
                          <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                            <i class="material-icons">settings</i>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button (click)="openEditWorkerDialog(element)" mat-menu-item>
                              &nbsp;<mat-icon class="material-icons">edit</mat-icon>
                              &nbsp;<span>Edit</span>
                            </button>
                            <button (click)="openSettingsWorkerDialog(element)" mat-menu-item>
                              &nbsp;<mat-icon class="material-icons">delete</mat-icon>
                              &nbsp;<span>DELETE</span>
                            </button>
                          </mat-menu> -->
                        </div>
                        <div class="basic-details">
                          <mat-icon class="material-icons">
                            email_on
                          </mat-icon>{{element.email}}
                        </div>
                        <div class="basic-details">
                          <mat-icon class="material-icons">
                            phone_on
                          </mat-icon>{{element.mobile}}
                        </div>
                      </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="credits">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Credits </th>
                      <td class="table-cell" mat-cell *matCellDef="let element"> {{element.credits}} </td>
                    </ng-container> -->
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="action">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                      <td class="table-cell" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:flex-start;">
                          <button (click)="openEditWorkerDialog(element)" class="btn v2"><i class="ion-edit"></i>
                            Edit</button>
                          <div>&nbsp;&nbsp;</div>
                          <button (click)="openSettingsWorkerDialog(element)" class="btn v5"><i
                              class="ion-android-delete"></i>
                            Delete</button>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                      <td class="table-cell" mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="free_trial">
                          <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Free_trial</th>
                          <td class="table-cell" mat-cell *matCellDef="let element">
                            <div style = "color: green" *ngIf = "element.free_trial"> Available</div>
                            <div style = "color: red" *ngIf = "!element.free_trial"> Not Available</div>
                           </td>
                        </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="workersDisplayedColumns">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: workersDisplayedColumns;" (click)="selection.toggle(row)">
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
  <!--Dashboard content Wrapper ends-->
</div>