<div>
    <div style="margin-bottom: 15px">
        <label class="top-label">Delete Images</label>
        <i class=" cross material-icons" (click)="closeDialog(null)">
            close
        </i>
    </div>
    <div>
        Are you sure you want to delete the selected images.
        <br><br>
        <button mat-raised-button color="primary" (click)="delete()">Confirm</button>
        &nbsp;&nbsp;&nbsp; &nbsp;
        <button mat-raised-button (click)="closeDialog(null)">Cancel</button>
    </div>
</div>