import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { Subject } from 'rxjs';
@Injectable()

export class LoaderService {

  public isLoading = new BehaviorSubject(false);
  public loaderStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  displayLoader(value: boolean) {
    this.loaderStatus.next(value);
  }
}