import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import 'rxjs/add/operator/toPromise';
import { environment } from "../../environments/environment";
import { AppGlobals } from "./app-globals";
import { Observable } from "rxjs/internal/Observable";
import { SendProposal } from "../models/send-proposal";

@Injectable()
export class SpaceService {
  private _selectedLocationId;

  constructor(private http: HttpClient, private _appGlobals: AppGlobals) {
    this._appGlobals.selectedLocationId.subscribe(
      (id) => (this._selectedLocationId = id),
    );
  }

  private headers = new HttpHeaders({ "Content-Type": "application/json" });

  private base_url = "api/v1/admin";

  private getAllUrl = environment.apiUrl + this.base_url + "/spaces";
  private ggetSpacesByAreaForProposalsUrl =
    environment.apiUrl + "api/v1/spaces/getSpacesByAreaForProposals";
  private getSpacesByCityIdForProposalsUrl =
    environment.apiUrl + `api/v1/spaces/getSpacesByCityIdForProposals`;
    private getSpacesByCityId = 
    environment.apiUrl + `api/v1/spaces/getSpacesByCityId`;
  private updateUrl = environment.apiUrl + this.base_url + "/update_space";
  private updateCoverImageUrl =
    environment.apiUrl + this.base_url + "/updateCoverImage";
  private deleteUrl = environment.apiUrl + this.base_url + "/deleteSpace";
  private addUrl = environment.apiUrl + this.base_url + "/add_space";
  private deleteDetailsImageUrl =
    environment.apiUrl + this.base_url + "/deleteDetailsImage";
  private sendMailForLeadsUrl =
    environment.apiUrl + this.base_url + "/sendMailForLead";
  private getSpaceDetailsUrl =
    environment.apiUrl + this.base_url + "/getSpaceDetailsForAdmin";
  private uploadImagesUrl =
    environment.apiUrl + this.base_url + "/uploadImages";
  private deleteImagesUrl =
    environment.apiUrl + this.base_url + "/deleteImages";

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  private _options = {
    headers: this.headers,
    withCredentials: true,
  };

  getAll(search_string): Promise<any> {
    let search_condition = search_string ? `?search=${search_string}` : ``;
    return this.http
      .get(this.getAllUrl + search_condition, {
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpacesByCityIdForProposals(params): Promise<any> {
    return this.http
      .post(this.getSpacesByCityIdForProposalsUrl, JSON.stringify(params), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  updateCoverImage(cover_image, space_id): Promise<any> {
    return this.http
      .post(
        this.updateCoverImageUrl,
        JSON.stringify({ cover_image, space_id }),
        { headers: this.headers, withCredentials: true },
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  sendMailForLead(send_proposal): Promise<any> {
    return this.http
      .post(
        this.sendMailForLeadsUrl,
        send_proposal,
        {
          withCredentials: true,
        }
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpacesByAreaForProposals(params): Promise<any> {
    let data = {
      lat: params.area_lat,
      longi: params.area_long,
      resource_type: params.resource_type,
    };
    return this.http
      .post(this.ggetSpacesByAreaForProposalsUrl, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  update(space_id, data): Promise<any> {
    return this.http
      .put(this.updateUrl + "/" + space_id, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  delete(space_id): Promise<any> {
    return this.http
      .delete(this.deleteUrl + "/" + space_id, {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpaceDetails(space_id): Promise<any> {
    return this.http
      .get(this.getSpaceDetailsUrl + "/" + space_id, {
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  add(data): Promise<any> {
    return this.http
      .post(this.addUrl, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  deleteDetailsImage(space_id, img): Promise<any> {
    return this.http
      .post(
        this.deleteDetailsImageUrl + "/" + img,
        JSON.stringify({ space_id }),
        { headers: this.headers, withCredentials: true },
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  uploadImages(space_id, formData): Promise<any> {
    return this.http
      .post(this.uploadImagesUrl + "/" + space_id, formData, {
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  deleteImages(space_id, images): Promise<any> {
    return this.http
      .post(
        this.deleteImagesUrl + "/" + space_id,
        { images },
        { withCredentials: true },
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getAllSpacesCities(): Observable<any> {
    return this.http.get(
      environment.apiUrl + "api/v1/spaces/getAllSpacesCities",
    );
  }

  getSpacesByCity(cityId): Observable<any> {
    return this.http.get(
      environment.apiUrl + "api/v1/spaces/getSpacesByCityId/" + cityId,
    );
  }

  getNearBySpacesByCityName(cityName): Observable<any> {
    return this.http.get(
      environment.apiUrl + "api/v1/spaces/getNearBySpacesByCityId/" + cityName,
    );
  }

  getSpacesByCityAndLocation(city_id, location_name): Observable<any> {
    return this.http.post(
      environment.apiUrl + "api/v1/spaces/getSpacesByCityAndLocation",
      { city_id, location_name },
    );
  }
}
