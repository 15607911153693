import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
// import { MatOption } from '@angular/material/option';
// import { MatOption } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
// import { GlobalVariables } from '../global/global-variables';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { ManagerService } from '../services/manager.service';
import { UpdateManagerDialog } from './update-manager-dialog.component';
import { DeleteManagerDialog } from './delete-manager-dialog.component';
import { FilterPipe } from '../filter.pipe';

@Component({
  selector: 'app-managers',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagersComponent implements OnInit {
  private managers_data = [];
  public selected_managers_data = [];
  public search_string;
  public ManagersDataSource;

  constructor(
    private loaderService: LoaderService,
    public manager_dialog: MatDialog,
    public snackBar: MatSnackBar,
    public manager_viewContainerRef: ViewContainerRef,
    private _managerservice: ManagerService,
  ) { }
  manager_dialogRef: MatDialogRef<any>
  selection = new SelectionModel<Worker>(true, []);

  ManagersDisplayedColumns: string[] = ['id', 'name', 'space_name', 'admin', 'status'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.populateManagers();
  }

  public populateManagers() {
    this._managerservice.getAllDetails()
      .then(res => {
        if (res.success) {
          console.log(res, 'managers');
          this.managers_data = Object.assign([], res.data);
          this.selected_managers_data = this.managers_data;
          this.ManagersDataSource = new MatTableDataSource(this.selected_managers_data)
          this.ManagersDataSource.sort = this.sort;
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }
  applyFilter(filterValue: string) {
    this.ManagersDataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearSearchInput() {
    this.search_string = '';
    this.applyFilter('');
  }
  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openEditManagerDialog(manager_obj, action_type) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.manager_viewContainerRef;
    config.disableClose = true;
    config.minWidth = '80vw';

    this.manager_dialogRef = this.manager_dialog.open(UpdateManagerDialog, config);
    this.manager_dialogRef.componentInstance.ref = this.manager_dialogRef;
    this.manager_dialogRef.componentInstance.selected_manager = manager_obj;
    this.manager_dialogRef.componentInstance.action_type = action_type;
    this.manager_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateManagers();
      }
      this.manager_dialogRef = null;
    });
  }

  openDeleteManagerDialog(manager_obj) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.manager_viewContainerRef;
    config.disableClose = true;

    this.manager_dialogRef = this.manager_dialog.open(DeleteManagerDialog, config);
    this.manager_dialogRef.componentInstance.ref = this.manager_dialogRef;
    this.manager_dialogRef.componentInstance.selected_manager = manager_obj;
    this.manager_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateManagers();
      }
      this.manager_dialogRef = null;
    });
  }
}