import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
import { MeetingRoomService } from '../services/meetingRoom.service';
import { GlobalVariables } from '../global/global-variables';
// import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { FilterPipe } from '../filter.pipe';
// import {RatingModule} from "ngx-rating";
import { UpdateMeetingRoomDialog } from './add-update-meetingroom-dialog.component';
import { DeleteMeetingRoomDialog } from './delete-meetingroom.component';

export class MeetingRoom {
  id: number;
  name: string;
  space_id: number;
  price: number;
  capacity: number;
  space_name: string;
}

@Component({
  selector: 'app-meetingroom',
  templateUrl: './meetingroom.component.html',
  styleUrls: ['./meetingroom.component.css']
})
export class MeetingRoomComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private _meetingroomservice: MeetingRoomService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public meetingroom_dialog: MatDialog,
    public meetingroom_viewContainerRef: ViewContainerRef,
    private _appGlobals: AppGlobals,
    public snackBar: MatSnackBar
  ) { }
  meetingroom_dialogRef: MatDialogRef<any>
  public meetingroom_data;
  public MeetingRoomsDataSource;
  public search_text: string;
  public website_base_url: string = 'http://localhost:4200';
  selection = new SelectionModel<MeetingRoom>(true, []);
  MeetingRoomDisplayedColumns: string[] = ['id', 'name', 'is_active', 'price', 'capacity', 'space_name'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.populateMeetingRooms();
  }

  public populateMeetingRooms() {
    this._meetingroomservice.getAllMeetingRooms()
      .then(res => {
        if (res.success) {
          this.meetingroom_data = Object.assign([], res.data);
          this.MeetingRoomsDataSource = new MatTableDataSource(this.meetingroom_data)
          this.MeetingRoomsDataSource.sort = this.sort;
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  applyFilter(filterValue: string) {
    this.MeetingRoomsDataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearSearchInput() {
    this.search_text = '';
  }

  openEditMeetingRoomDialog(meetingroom_obj, action_type) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.meetingroom_viewContainerRef;
    config.disableClose = true;

    this.meetingroom_dialogRef = this.meetingroom_dialog.open(UpdateMeetingRoomDialog, config);
    this.meetingroom_dialogRef.componentInstance.ref = this.meetingroom_dialogRef;
    this.meetingroom_dialogRef.componentInstance.selected_meetingroom = meetingroom_obj;
    this.meetingroom_dialogRef.componentInstance.action_type = action_type;
    this.meetingroom_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateMeetingRooms();
      }
      this.meetingroom_dialogRef = null;
    });
  }

  openDeleteMeetingRoomDialog(meetingroom_obj) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.meetingroom_viewContainerRef;
    config.disableClose = true;

    this.meetingroom_dialogRef = this.meetingroom_dialog.open(DeleteMeetingRoomDialog, config);
    this.meetingroom_dialogRef.componentInstance.ref = this.meetingroom_dialogRef;
    this.meetingroom_dialogRef.componentInstance.selected_meetingroom = meetingroom_obj;
    this.meetingroom_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateMeetingRooms();
      }
      this.meetingroom_dialogRef = null;
    });
  }
}
