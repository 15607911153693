  <div class="page-wrapper">

    <!--Sidebar Menu Starts-->

    <!--Sidebar Menu ends-->

    <!--Dashboard content Wrapper starts-->
    <div class="dash-content-wrap">
      <!--Dashboard breadcrumb starts-->
      <div class="dash-breadcrumb">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="dash-breadcrumb-content">
                <div class="dash-breadcrumb-left">
                  <div class="breadcrumb-menu text-right sm-left">
                    <p class="list-address">Showing <b>{{list_queries.length}}</b> results</p>
                  </div>
                </div>
                <!-- <a class="btn v3" href="add-listing.html"><i class="ion-plus-round"></i>Add Listing </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Dashboard breadcrumb ends-->
      <!--Dashboard content starts-->
      <div class="dash-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="dash-bookings">
                <div class="act-title">
                  <h5><i class="ion-ios-copy-outline"></i> Queries</h5>
                </div>
                <div class="db-booking-wrap" *ngFor="let element of _queries_data">
                  <div class="db-booking-item">
                    <h4>{{element.name}}</h4>
                    <span><i class="ion-android-call"></i> &nbsp;{{element.mobile}}</span> &nbsp; &nbsp;
                    <span><i class="ion-android-mail"></i> &nbsp;{{element.email}}</span>

                    <div class="booking-info">
                      <h6>Added At: </h6>
                      <ul class="booking_list">
                        <li>
                          <span>{{element.added_at}}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="booking-info"  style="max-width: 80%;">
                      <h6>Message: </h6>
                      <ul class="booking_list">
                        <li><span>{{element.message}}</span></li>
                      </ul>
                    </div>
                    <!-- <a class="btn v8 mt-4" (click)="sendMessageDialog(element, 1)"
                      *ngIf="element.city_name && element.location_name"><i class="ion-android-mail"></i> Send
                      Proposal</a>
                    <a class="btn v8 mt-4" (click)="sendMessageDialog(element, 2)"
                      *ngIf="element.city_name && !element.location_name"><i class="ion-android-mail"></i> Send
                      Proposal</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Dashboard content ends-->
      <!--Dashboard footer ends-->
    </div>
    <!--Dashboard content Wrapper ends-->

  </div>