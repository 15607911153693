import { Component, ViewChild, ViewContainerRef, OnInit } from "@angular/core";
// import { MatOption } from '@angular/material/option';
// import { MatOption } from '@angular/material';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectionModel } from "@angular/cdk/collections";
import { AppGlobals } from "../services/app-globals";
import { LoaderService } from "../services/loader.service";
import { GlobalVariables } from "../global/global-variables";
import { CsvDownloadFileService } from "../services/csv-download.service";
import * as _ from "lodash";
import { ManagerService } from "../services/manager.service";
import { QueriesService } from "../services/queries.service";
import { FilterPipe } from "../filter.pipe";
import { SendMessageDialog } from "./send-message-dialog.component";
// import { SendMessageDialog } from "./pupUp/send-message-dialog.component";
import { AddLeadDialog } from "./add-lead-dialog.component";

@Component({
  selector: "app-managers",
  templateUrl: "./leads.component.html",
  styleUrls: ["./leads.component.css"],
})
export class LeadsComponent implements OnInit {
  public leads_data = [];
  // public selected_managers_data = [];
  public search_string;
  public resource_type_names = GlobalVariables.resource_type_names;
  constructor(
    private loaderService: LoaderService,
    public leads_dialog: MatDialog,
    public downloadService: CsvDownloadFileService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    // public leads_dialog: MatDialog,
    public leads_viewContainerRef: ViewContainerRef,
    private _managerservice: ManagerService,
    private _queriesservice: QueriesService
  ) {}
  leads_dialogRef: MatDialogRef<any>;
  selection = new SelectionModel<Worker>(true, []);
  // leads_dialogRef: MatDialogRef<any>

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.populateLeads();
  }

  public populateLeads() {
    this._queriesservice
      .getAllLeads()
      .then((res) => {
        if (res.success) {
          console.log("321", res.data);
          this.leads_data = Object.assign(
            [],
            res.data.map((loc: any) => {
              return { ...loc, location_name: "Andheri East", city_name:"Mumbai" };
            })
          );
          console.log(this.leads_data);
        } else {
          this._openSnackBar(res.message || "Some error occured", "Dismiss");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public downloadCSV() {
    return this.downloadService.downloadFile(
      this.leads_data,
      [
        "id",
        "name",
        "email",
        "mobile",
        "location_name",
        "city_name",
        "actual_space_name",
        "resource_name",
        "company_name",
        "persons",
        "added_at",
      ],
      "leads.csv"
    );
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  addLeadDialog() {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.leads_viewContainerRef;
    config.disableClose = true;

    this.leads_dialogRef = this.leads_dialog.open(AddLeadDialog, config);
    this.leads_dialogRef.componentInstance.ref = this.leads_dialogRef;
    this.leads_dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.populateLeads();
      }
      this.leads_dialogRef = null;
    });
  }

  sendMessageDialog(lead_obj) {
    let config = new MatDialogConfig();
    let locality_type;
    if (lead_obj.city_name && lead_obj.location_name) {
      locality_type = 1;
    }
    if (lead_obj.city_name && !lead_obj.location_name) {
      locality_type = 2;
    }
    if (!lead_obj.city_name && !lead_obj.location_name) {
      locality_type = 3;
    }
    config.viewContainerRef = this.leads_viewContainerRef;
    config.disableClose = true;
    config.minWidth = '95vw';
    config.minHeight = '95vh';
    config.data = lead_obj;

    this.leads_dialogRef = this.leads_dialog.open(SendMessageDialog, config);
    this.leads_dialogRef.componentInstance.ref = this.leads_dialogRef;
    this.leads_dialogRef.componentInstance.selected_lead = lead_obj;
    this.leads_dialogRef.componentInstance.locality_type = locality_type;
    this.leads_dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.populateLeads();
      }
      this.leads_dialogRef = null;
    });
  }
}
