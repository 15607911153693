import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class ManagerService {

  public _selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this._selectedLocationId = id);
  }

  private base_url = 'api/v1/admin';
  private addDetailsUrl = environment.apiUrl + this.base_url +'/addManager';
  private deleteDetailsUrl = environment.apiUrl + this.base_url +'/delete_manager';
  private updateDetailsUrl = environment.apiUrl + this.base_url +'/update_manager';
  private getAllDetailsUrl = environment.apiUrl + this.base_url +'/managers';
  private getSpacesUrl = environment.apiUrl + this.base_url + '/spaces';
  private getBasicDetailsUrl = environment.apiUrl + this.base_url + '/basic_details';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  private _options = {
    headers: this.headers, 
    withCredentials: true
  };

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAllDetails (): Promise<any> {

    return this.http.get(this.getAllDetailsUrl,
    {
      withCredentials: true
    })
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  addDetails (details): Promise<any> {
    return this.http.post(this.addDetailsUrl, details, {
      withCredentials: true,
    })
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  updateDetails (manager_id, manager_details): Promise<any> {
    for (var pair of manager_details.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    return this.http.put(this.updateDetailsUrl + '/' + manager_id, manager_details, {
      withCredentials: true,
    })
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  delete (manager_id): Promise<any> {
    return this.http.delete(this.deleteDetailsUrl + '/' + manager_id, {headers: this.headers, withCredentials: true})
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  getAllSpaces (): Promise<any> {

    return this.http.get(this.getSpacesUrl,
    {
      withCredentials: true
    })
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }

  getSpaceDetails ():Promise<any> {
    return this.http.get(this.getBasicDetailsUrl,
    {
      withCredentials: true
    })
    .toPromise()
    .then(res => res)
    .catch(this.handleError);
  }
}
