import { SendProposalOptions } from "./send-proposal-options";

export class SendProposal {
    options: SendProposalOptions[] = [];
    to: string = '';
    signature: string;
    subject: string = 'Your Workspace Options @ Flexo';  //Re: Workspace Options For You @ Zero Brokerage from Flexo
    heading: string = 'Office Space Options For You @ Zero Brokerage';
    description: string = `Greetings from Flexo! As discussed, please find workspace options matching your requirements. The commercials are subject to negotiation. Feel free to call me for any clarifications you may require. Please advise on your shortlist and a convenient day and time to schedule your visits.`;
    text: string = `Discalimer: The content of this document is private and confidential. No representation or warranty, whether express or implied, is given as to the accuracy or completeness of the information contained within. Pricing and availability are subject to change. Flexo Spaces does not accept any liability nor responsibility for any consequences of you or anyone else acting, or refraining to act, in reliance of the information. All intellectual property rights within this document are the sole property of the Flexo Spaces and may not be copied or distributed, in whole or in part, without written consent.`;
    lead_id: number;
    resource_type: number;
    attach_pdf_proposal: boolean = false;
    why_image: any;
    steps_image: any;
    why_image_link: any;
    steps_image_link: any;
    hide_why_image: boolean = false;
    hide_steps_image: boolean = false;
    advantage_text: string = `To Schedule Your Visits, Call Your Workspace Advisor Now!`;
    receiver_name: string;
    manager: {
        id: number;
        name: string;
        email: string;
        mobile: string;
        is_admin: any;
        status: any;
        image: string;
        spaces_data: any;
    } = {
        id: 0,
            name: '',
            email: '',
            mobile: '',
            is_admin: 0,
            status: 0,
            image: '',
            spaces_data: '',
        };
}