import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'logout',
  templateUrl: './logout-dialog.component.html'
})
export class LogoutDialog {

  constructor(
    public dialogRef: MatDialogRef<any>,
    private authenticationService: AuthenticationService 
  ) {}

  logout (): void {
    this.authenticationService.deletCookies()
    .then(data => {
      localStorage.removeItem('account_id');
      localStorage.removeItem('location_name');
      localStorage.removeItem('location_id');
      this.closeDialog({success: true});
    })
    .catch(error => {
      console.log(error);
    })
  }

  closeDialog(option = {}) {
    this.dialogRef.close(option);
  }
}
