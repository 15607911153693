import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { AppGlobals } from '../services/app-globals';
import { LoaderService } from '../services/loader.service';
import { MatSort } from '@angular/material/sort';
import { DeleteSpaceDialog } from './delete-space-dialog.component';
// import { MatOption } from '@angular/material/select';
import { SpaceService } from '../services/space.service';
// import { GlobalVariables } from '../global/global-variables';
import { Observable } from 'rxjs';
// import { UpdateSpaceDialog } from './update-space-dialog.component';
// import { DeleteSpaceDialog } from './delete-space-dialog.component';
// import { UploadImageDialog } from './upload-image-dialog.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { FilterPipe } from '../filter.pipe';
// import {RatingModule} from "ngx-rating";

export class Space {
  id: number;
  name: string;
  status: number;
  area_id: number;
  address: string;
  lat: number;
  longi: number;
  type: number;
  area_name: string;
  facilities: number[];
  cumulative_rating: any;
  teamcabin_count: number;
  is_daypass_available: number;
  city_name: string;
  actual_name: string;
  location_name: string;
  space_status_toggle: boolean;
}

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.css'],

})
export class SpacesComponent implements OnInit {

  public selected_type: number;
  public spaces_data: Space[];
  public selectedspaces_data: Space[];
  public list_space;
  public filtered_spaces_data: Space[];
  public search_string: string;
  public date_display;
  public date;
  public space_details;
  public selected_status = 1;
  isOpen: false;
  public website_base_url: string = 'https://www.flexospaces.com/';

  constructor(
    private loaderService: LoaderService,
    private _spaceservice: SpaceService,
    private spaceService: SpaceService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public space_dialog: MatDialog,
    public upload_dialog: MatDialog,
    public confirm_dialog: MatDialog,
    public confirm_viewContainerRef: ViewContainerRef,
    public space_viewContainerRef: ViewContainerRef,
    public upload_viewContainerRef: ViewContainerRef,
    private _appGlobals: AppGlobals,
    public snackBar: MatSnackBar
  ) { }

  dialogRef: MatDialogRef<any>
  confirm_dialogRef: MatDialogRef<any>
  space_dialogRef: MatDialogRef<any>
  upload_dialogRef: MatDialogRef<any>

  public spacesDataSource;
  public search_text: string;

  selection = new SelectionModel<Space>(true, []);
  spacesDisplayedColumns: string[] = ['id', 'name', 'status', 'area_name', 'about', 'address', 'lat', 'longi', 'cumulative_rating'];

  // @ViewChild('allSelected') private allSelected: MatOption;

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.selected_type = 0;
    this.populateSpaces();
  }

  public populateSpaces() {
    this._spaceservice.getAll(this.search_string)
      .then(res => {
        if (res.success) {
          this.spaces_data = Object.assign([], res.data);
          let spaces_service = []
          this.spaces_data.forEach(element => {
            spaces_service.push({ id: element.id, name: element.name, actual_name: element.actual_name });
            element.space_status_toggle = element.status ? true : false;
          })
          this._appGlobals.setSpacesData(spaces_service);
          this.changeStatus(this.selected_status);
          // this.changeType(null);
        }
        else {
          this._openSnackBar(res.message || "Some error occured", 'Dismiss');
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  public changeStatus(status: number) {
    this.selected_status = status;
    switch (status) {
      case 1:
        this.filtered_spaces_data = Object.assign([], _.filter(this.spaces_data, ['status', 1]));
        break;
      case 0:
        this.filtered_spaces_data = Object.assign([], _.filter(this.spaces_data, ['status', 0]));
        break;
      default:
        this.filtered_spaces_data = Object.assign([], this.spaces_data);
    }
  }

  private _openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  updateSpace(element) {
    let status = element.space_status_toggle ? 1 : 0
    this.spaceService.update(element.id, { status })
      .then(res => {
        if (res.success) {
          this._openSnackBar(res.message, "Dismiss");
          this.populateSpaces();
        }
      })
      .catch(error => {
        console.log(error)
        this._openSnackBar(`Something went wrong`, "Dismiss");
      })
  }

  applyFilter() {
    this.populateSpaces()
  }

  search(value) {
    this.filtered_spaces_data = this.filtered_spaces_data.filter(
      (val) => val['name'].includes(value))
    //Searched Data
    // console.log(this.searchedList)
  }

  public clearSearchInput() {
    this.search_string = '';
    this.changeStatus(this.selected_status);
  }

  openDeleteSpaceDialog(space_obj) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.space_viewContainerRef;
    config.disableClose = true;

    this.space_dialogRef = this.space_dialog.open(DeleteSpaceDialog, config);
    this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
    this.space_dialogRef.componentInstance.selected_space = space_obj;
    this.space_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.populateSpaces();
      }
      this.space_dialogRef = null;
    });
  }

  // openEditSpaceDialog(space_obj, action_type) {
  //   let config = new MatDialogConfig();
  //   config.viewContainerRef = this.space_viewContainerRef;
  //   config.disableClose = true;

  //   this.space_dialogRef = this.space_dialog.open(UpdateSpaceDialog, config);
  //   this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
  //   this.space_dialogRef.componentInstance.selected_space = space_obj;
  //   this.space_dialogRef.componentInstance.action_type = action_type;
  //   this.space_dialogRef.afterClosed().subscribe(result => {
  //     if (result && result.success) {
  //       this.populateSpaces();
  //       if (result.add_space) {
  //         this.openUploadImageDialog(result.space_obj)
  //       }
  //     }
  //     this.space_dialogRef = null;
  //   });
  // }

  // openDeleteManagerDialog(space_obj) {
  //   let config = new MatDialogConfig();
  //   config.viewContainerRef = this.space_viewContainerRef;
  //   config.disableClose = true;

  //   this.space_dialogRef = this.space_dialog.open(DeleteSpaceDialog, config);
  //   this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
  //   this.space_dialogRef.componentInstance.selected_space = space_obj;
  //   this.space_dialogRef.afterClosed().subscribe(result => {
  //     if(result && result.success){
  //       this.populateSpaces();
  //     }
  //     this.space_dialogRef = null;
  //   });
  // }

  // openUploadImageDialog(space_obj) {
  //   let config = new MatDialogConfig();
  //   config.viewContainerRef = this.space_viewContainerRef;
  //   config.disableClose = true;

  //   this.space_dialogRef = this.space_dialog.open(UploadImageDialog, config);
  //   this.space_dialogRef.componentInstance.ref = this.space_dialogRef;
  //   this.space_dialogRef.componentInstance.selected_space_details_img_1 = space_obj.details_img_1;
  //   this.space_dialogRef.componentInstance.selected_space_details_img_2 = space_obj.details_img_2;
  //   this.space_dialogRef.componentInstance.selected_space_details_img_3 = space_obj.details_img_3;
  //   this.space_dialogRef.componentInstance.selected_space_banner_img = space_obj.banner_img;
  //   this.space_dialogRef.componentInstance.selected_space_id = space_obj.id;
  //   this.space_dialogRef.afterClosed().subscribe(result => {
  //     if (result && result.success) {
  //       this.populateSpaces();
  //     }
  //     this.space_dialogRef = null;
  //   });
  // }
}
