import { Component, OnInit, ViewChild, AfterViewInit, ViewContainerRef, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GlobalVariables } from '../global/global-variables';
import { LoaderService } from '../services/loader.service';
import { SpaceService } from '../services/space.service';
import { ShowImageDialog } from './show-image-dialog.component';
import { DeleteImageDialog } from './delete-image-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { Types } from './types';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import * as moment from 'moment';
declare var $: any;
declare var google;

export class Space {
  id: number
  actual_name: string;
  about: string;
  facilities: number[];
  images: string[];
  location_name: string;
  landmark: string;
  longi: number;
  lat: number;
  status: number;
  has_247_access: number;
  capacity: number;
  near_by_metro: string;
  city_name: string;
  rating: number;
  near_by_railway: string;
  metro_distance: number;
  railway_distance: number;
  mon_friday_opening_time: string;
  mon_friday_closing_time: string;
  sunday_opening_time: string;
  sunday_closing_time: string;
  saturday_opening_time: string;
  saturday_closing_time: string;
  privatecabin_price: number;
  desks_price: number;
  customized_space_price: number;
  flexible_desk_price: number;
  virtual_office_price: number;
  meeting_room_price: number;
  training_room_price: number;
  address: string;
  contact_city_name: string;
  state: string;
  country: string;
  zip_code: string;
  contact_name: string;
  contact_mobile: string;
  contact_email: string;
  alternate_contact_name: string;
  alternate_contact_email: string;
  alternate_contact_mobile: string;
  youtube_url: string;
  updated_at: string;
}
export class Area {
  name: string;
  id: number;
}

@Component({
  selector: 'app-add-space',
  templateUrl: './add-space.component.html',
  styleUrls: ['./add-space.component.css']
})
export class AddSpaceComponent implements OnInit {

  public space_details: Space;
  public types = Types;
  constructor(
    public snackBar: MatSnackBar,
    private spaceService: SpaceService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public add_space_dialog: MatDialog,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    // public leads_dialog: MatDialog,
    public add_space_viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef,
    private loaderService: LoaderService
  ) {
    this.space_details = new Space;
  }
  public geocoder = new google.maps.Geocoder();
  public space_id;
  public is_id_exist: boolean = false;
  public page1 = true;
  public page2 = false;
  public page3 = false;
  public page4 = false;
  public page5 = false;
  public page6 = false;
  public page1_filled = true;
  public page2_filled = false;
  public page3_filled = false;
  public page4_filled = false;
  public page5_filled = false;
  public page6_filled = false;
  public is_saturday_closed = false;
  public is_sunday_closed = false;
  public price_message = null;
  public page1_important = false;
  public facility_clicked = false;
  public page2_important = false;
  public page3_important = false;
  public page4_important = false;
  public page5_important = false;
  public page6_important = false;
  public ratings = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
  public is_add_button_disabled: boolean = false;
  public message;
  public has_247_access_toggle = true;
  public res_array = [];
  public delete_images = [];
  add_space_dialogRef: MatDialogRef<any>

  public resource_types = GlobalVariables.resource_types;
  public facilities_list = GlobalVariables.facilities
  public aws_base_url = "https://s3.ap-south-1.amazonaws.com/" + environment.aws_bucket_path + '/details_images/';
  public space_facilities = [];
  filesToUpload: Array<File> = [];

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.space_id = params.get('id');
        if (this.space_id) {
          this.is_id_exist = true;
          this.getDetails();
        }
      }
    )
    this.space_details.facilities = [];
  }

  ngAfterViewInit() {
    $('.list-details-tab li').on('click', (function () {
      $('li').removeClass("active");
      $(this).addClass("active");
    }));
  }

  showImageDialog(image) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.add_space_viewContainerRef;
    // config.disableClose = true;

    this.add_space_dialogRef = this.add_space_dialog.open(ShowImageDialog, config);
    this.add_space_dialogRef.componentInstance.ref = this.add_space_dialogRef;
    this.add_space_dialogRef.componentInstance.image = image;
    this.add_space_dialogRef.componentInstance.id = this.space_id;
    this.add_space_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
      }
      this.add_space_dialogRef = null;
    });
  }

  deleteImageDialog() {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.add_space_viewContainerRef;
    config.disableClose = true;

    this.add_space_dialogRef = this.add_space_dialog.open(DeleteImageDialog, config);
    this.add_space_dialogRef.componentInstance.ref = this.add_space_dialogRef;
    // this.add_space_dialogRef.componentInstance.image = image;
    this.add_space_dialogRef.componentInstance.id = this.space_id;
    this.add_space_dialogRef.componentInstance.delete_images = this.delete_images;
    this.add_space_dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.delete_images = null;
        this.getDetails();
      }
      this.add_space_dialogRef = null;
    });
  }

  getDetails() {
    this.spaceService.getSpaceDetails(this.space_id)
      .then(res => {
        this.space_details = Object.assign({}, res.data);
        this.space_facilities = Object.assign([], res.data.facilities);
        this.has_247_access_toggle = this.space_details.has_247_access ? true : false;
        this.resource_types.forEach(resource_type => {
          if (this.space_details[resource_type.column_name]) {
            this.res_array.push(resource_type.id);
            $('#res-' + resource_type.id).removeAttr('disabled');
          }
        })
        // if (data.data.privatecabin_price) {
        //   this.res_array.push(1);
        //   $('#res-1').removeAttr('disabled');
        // }
        // if (data.data.customized_space_price) {
        //   this.res_array.push(2);
        //   $('#res-2').removeAttr('disabled');
        // }
        // if (data.data.desks_price) {
        //   this.res_array.push(3);
        //   $('#res-3').removeAttr('disabled');
        // }
        // if (data.data.flexible_desk_price) {
        //   this.res_array.push(4);
        //   $('#res-4').removeAttr('disabled');
        // }
        // if (data.data.virtual_office_price) {
        //   this.res_array.push(5);
        //   $('#res-5').removeAttr('disabled');
        // }
        // if (data.data.meeting_room_price) {
        //   this.res_array.push(6);
        //   $('#res-6').removeAttr('disabled');
        // }
        // if (data.data.training_room_price) {
        //   this.res_array.push(7);
        //   $('#res-7').removeAttr('disabled');
        // }
      })
      .catch(error => {
        this.message = "Something went wrong";
        this.openSnackBar(this.message, "Dismiss");
      })
  }

  fileChangeEvent(fileInput) {
    this.filesToUpload = [...this.filesToUpload, ...<Array<File>>fileInput.target.files];
  }

  getSizeInMb(size) {
    return (size / 1048576).toFixed(1);
  }

  public nextHelper(page) {
    if (page == 1) {
      if (!this.space_details.actual_name || !this.space_details.about || !this.facility_clicked) {
        this.page1_important = true;
      } else {
        this.page1 = false;
        this.page2 = true;
        this.page2_filled = true;
      }
    } else if (page == 2) {
      if (!this.space_details.lat || !this.space_details.longi || !this.space_details.landmark ||
        !(this.space_details.lat >= -90 && this.space_details.lat <= 90) || !(this.space_details.longi >= -180 && this.space_details.longi <= 180)) {
        this.page2_important = true;
      } else {
        this.page2 = false;
        this.page3 = true;
        this.page3_filled = true;
      }
    } else if (page == 3) {
      this.page3 = false;
      this.page4 = true;
      this.page4_filled = true;
      // this.show_page4 = true;
    } else if (page == 4) {
      if (this.space_details.privatecabin_price == null && this.space_details.desks_price == null && this.space_details.flexible_desk_price == null &&
        this.space_details.meeting_room_price == null && this.space_details.customized_space_price == null && this.space_details.virtual_office_price == null && this.space_details.training_room_price == null) {
        this.price_message = `Enter price for atleast one type.`
      } else {
        this.page4 = false;
        this.page5 = true;
        this.page5_filled = true;
      }
    } else if (page == 5) {
      this.page4 = false;
      this.page5 = true;
      this.page6_filled = true;
    } else if (page == 6) {
      this.page5 = false;
      this.page6 = true;
    }

  }

  public navHelper(page) {
    if (page == 1) {
      this.page1 = true;
      this.page2 = false;
      this.page3 = false;
      this.page4 = false;
      this.page5 = false;
    } else if (page == 2) {
      this.page1 = false;
      this.page2 = true;
      this.page4 = false;
      this.page5 = false;
      this.page3 = false;
    } else if (page == 3) {
      this.page1 = false;
      this.page2 = false;
      this.page5 = false;
      this.page3 = true;
      this.page4 = false;
    } else if (page == 4) {
      this.page1 = false;
      this.page2 = false;
      this.page5 = false;
      this.page3 = false;
      this.page4 = true;
    } else if (page == 5) {
      this.page1 = false;
      this.page2 = false;
      this.page3 = false;
      this.page4 = false;
      this.page5 = true;
    }
  }
  public updateSpaceFacilities(facility_id, event) {
    const index = this.space_facilities.indexOf(facility_id);
    this.facility_clicked = true;
    if (event.currentTarget.checked) {
      this.space_facilities.push(facility_id);
    } else if (index > -1) {
      this.space_facilities.splice(index, 1);
    }
  }

  public deleteImage(image, event) {
    const index = this.delete_images.indexOf(image);
    if (event.currentTarget.checked) {
      this.delete_images.push(image);
    } else {
      if (index > -1) {
        this.delete_images.splice(index, 1);
      }
    }
  }

  public makeCoverImage() {
    let cover_image = this.delete_images[0];
    this.spaceService.updateCoverImage(cover_image, this.space_id)
      .then(res => {
        if (res && res.success) {
          this.openSnackBar(res.message, "Dismiss");
          this.getDetails();
        } else {
          this.openSnackBar(
            'Something Went Wrong', "Dismiss");
        }
      })
      .catch(err => {
        throw err;
      })
  }

  public uploadImages() {
    this.is_add_button_disabled = true;
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;

    for (let i = 0; i < files.length; i++) {
      formData.append("uploads[]", files[i]);
    }
    this.spaceService.uploadImages(this.space_id, formData)
      .then(res => {
        if (res && res.success) {
          this.openSnackBar(res.message, "Dismiss");
          this.filesToUpload = [];
          this.getDetails();
        }
      })
      .catch(err => {
        // throw err;
      })
  }

  geocode = () => {
    // if (!this.space_details.address || !this.space_details.contact_city_name || !this.space_details.state ||
    //   !this.space_details.country || !this.space_details.zip_code || !this.space_details.contact_name
    //   || !this.space_details.contact_email || !this.space_details.contact_mobile || !this.space_details.alternate_contact_name ||
    //   !this.space_details.alternate_contact_email || !this.space_details.alternate_contact_mobile) {
    //   this.page5_important = true;
    // } else {
      this.geocoder.geocode({ location: { lat: +this.space_details.lat, lng: +this.space_details.longi } }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            let lat_long_result = results[0].address_components;
            for (let i = 0; i < lat_long_result.length - 1; i++) {
              if (!this.space_details.location_name && (lat_long_result[i].types.includes("sublocality") || lat_long_result[i].types.includes("sublocality_level_1"))) {
                this.space_details.location_name = lat_long_result[i].long_name;
              }
              if (lat_long_result[i].types.includes("locality")) {
                this.space_details.city_name = lat_long_result[i].long_name;
              }
            }
          }
          this.onGeneralDetailsSubmit();
        }
      }
      );
    // }

  }

  satSunClosed(id, event) {
    if (id == 1) {
      if (this.is_saturday_closed) {
        this.is_saturday_closed = false;
      } else {
        this.is_saturday_closed = true;
        this.space_details.saturday_closing_time = null;
        this.space_details.saturday_opening_time = null;
      }
    }
    if (id == 2) {
      if (this.is_sunday_closed) {
        this.is_sunday_closed = false;
      } else {
        this.is_sunday_closed = true;
        this.space_details.sunday_closing_time = null;
        this.space_details.sunday_opening_time = null;
      }
    }
  }

  setMinimumResourcePrice(resource_type_id, event) {
    let element = $('#res-' + resource_type_id);
  }

  setMinimumResourcePriceVisible(resource_type_id, event) {
    let element = $('#res-' + resource_type_id);
    if (event.currentTarget.checked) {
      element.removeAttr('disabled');
    } else {
      if (!this.is_id_exist) {
        if (event.currentTarget.id == 'res-check-1') {
          this.space_details.privatecabin_price = null;
        }
        if (event.currentTarget.id == 'res-check-2') {
          this.space_details.customized_space_price = null;
        }
        if (event.currentTarget.id == 'res-check-3') {
          this.space_details.desks_price = null;
        }
        if (event.currentTarget.id == 'res-check-4') {
          this.space_details.flexible_desk_price = null;
        }
        if (event.currentTarget.id == 'res-check-5') {
          this.space_details.virtual_office_price = null;
        }
        if (event.currentTarget.id == 'res-check-6') {
          this.space_details.meeting_room_price = null;
        }
        if (event.currentTarget.id == 'res-check-7') {
          this.space_details.training_room_price = null;
        }
      }
      element.attr('disabled', 'disabled');
    }
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onGeneralDetailsSubmit() {
    this.space_details.facilities = this.space_facilities;
    this.space_details.has_247_access = this.has_247_access_toggle ? 1 : 0;
    if (this.is_id_exist) {
      this.space_details.updated_at = moment().format("YYYY-MM-DD");
      this.spaceService.update(this.space_id, this.space_details)
        .then(res => {
          if (res.success) {
            this.ngZone.run(() => {
              this.nextHelper(6);
              this.message = res.message;
              this.openSnackBar(this.message, "Dismiss");
            })
          }
        })
        .catch(error => {
          console.log(error);
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
        })
    } else {
      this.spaceService.add(this.space_details)
        .then(res => {
          if (res.success) {
            this.ngZone.run(() => {
              this.nextHelper(6);
              this.space_id = res.data.id;
              this.message = res.message;
              this.openSnackBar(this.message, "Dismiss");
            })
          }
        })
        .catch(error => {
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
        })
    }

  }
}
