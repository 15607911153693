<div>
  <div>
    <label class="top-label">Create Lead</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <form #spaceForm="ngForm" (ngSubmit)="submitForm()" class="space-form">
    <mat-form-field class="space-form-inputs" appearance="outline">
      <mat-label>Location</mat-label>
      <input autocomplete="off" #autocomplete name="location_name" matInput
        placeholder="Start typing City or Area name and select from dropdown. " required>
      <mat-icon matSuffix>location_on</mat-icon>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Select Resource</mat-label>
      <mat-select placeholder="Select Resource" [(ngModel)]="space_type" name="space_type" required>
        <mat-option *ngFor="let resource_type of resource_types" (click)="onResourceTypeSelection(resource_type.id)"
          [value]="resource_type.id">
          {{resource_type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="is_select_date_time_visible" class="space-small-form-inputs">
      <mat-label>Choose a date</mat-label>
      <input matInput type="datetime-local" [(ngModel)]="booking_date_time">
    </mat-form-field><br>
    <p>Persons</p>
    <ul class="resource-types-container">
      <li class="resource-list" *ngFor="let num of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
        <mat-radio-button color="primary" (click)="setNoOfPeople(num)">
          <span>{{num}}</span>
        </mat-radio-button>
      </li>
      <li class="resource-list">
        <mat-radio-button color="primary" (click)="setNoOfPeople(21)">
          <span>21-50</span>
        </mat-radio-button>
      </li>
      <li class="resource-list">
        <mat-radio-button color="primary" (click)="setNoOfPeople(51)">
          <span>51-100</span>
        </mat-radio-button>
      </li>
      <li class="resource-list">
        <mat-radio-button color="primary" (click)="setNoOfPeople(100)">
          <span>100+</span>
        </mat-radio-button>
      </li>
    </ul><br>
    <mat-form-field class="space-form-inputs" appearance="outline">
      <mat-label>Company Name</mat-label>
      <input [(ngModel)]="company_name" name="company_name" matInput placeholder="Company" required>
    </mat-form-field>
    <mat-form-field class="space-form-inputs" appearance="outline">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="name" name="name" matInput placeholder="Enter Name" required>
    </mat-form-field>
    <mat-form-field class="space-form-inputs" appearance="outline">
      <mat-label>Email</mat-label>
      <input [(ngModel)]="email" name="email" matInput placeholder="Enter Email" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Mobile</mat-label>
      <input type="number" [(ngModel)]="mobile" name="mobile" min="10" max="10" matInput placeholder="Enter mobile"
        required>
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" type="submit" [disabled]="!spaceForm.form.valid">Submit</button>
    </div>
  </form>
</div>