export const GlobalVariables = {
  resource_types: [
    {
      name: "Private Office",
      id: 1,
      column_name: "privatecabin_price"
    }, {
      name: "Customized space",
      id: 2,
      column_name: "customized_space_price"
    }, {
      name: "Dedicated Desk",
      id: 3,
      column_name: "desks_price"
    }, {
      name: "Flexible Desk",
      id: 4,
      column_name: "flexible_desk_price"
    }, {
      name: "Virtual Office",
      id: 5,
      column_name: "virtual_office_price"
    }, {
      name: "Meeting Room",
      id: 6,
      column_name: "meeting_room_price"
    }, {
      name: "Training Room/Event Space",
      id: 7,
      column_name: "training_room_price"
    }
  ],

  resource_type_names: {
    1: "Private Office",
    2: "Customized space",
    3: "Dedicated Desk",
    4: "Flexible Desk",
    5: "Virtual Office",
    6: "Meeting Room",
    7: "Training Room/Event Space"
  },

  facilities: [
    { id: 1, name: '@4x7 Access' },
    { id: 2, name: 'Valet Parking' },
    { id: 3, name: '2W Parking' },
    { id: 4, name: '4W Parking' },
    { id: 5, name: 'Mail Service' },
    { id: 6, name: 'WiFi' },
    { id: 7, name: 'Leased Line' },
    { id: 8, name: 'Locker' },
    { id: 9, name: 'Printer/Scanner/Copy' },
    { id: 10, name: 'Whiteboard' },
    { id: 11, name: 'Projector' },
    { id: 12, name: 'TV' },
    { id: 13, name: 'Computer on hire' },
    { id: 14, name: 'Power backup' },
    { id: 15, name: 'Security' },
    { id: 16, name: 'CCTV' },
    { id: 17, name: 'AC' },
    { id: 18, name: 'Tea/Coffee' },
    { id: 19, name: 'Foods And Beverages' },
    { id: 20, name: 'Pantry' },
    { id: 21, name: 'Biometric Access/Access Card' },
    { id: 22, name: 'Lift Access' },
    { id: 23, name: 'Night Shift' },
    { id: 24, name: 'Onsite Staff' },
    { id: 25, name: 'Reception' },
    { id: 26, name: 'Intercom Facility' },
    { id: 27, name: 'Branding Board' },
    { id: 28, name: 'Recreational Area' },
    { id: 29, name: 'Metro Connectivity' },
    { id: 30, name: 'Tech Support' },
    { id: 31, name: 'Gym' },
    { id: 32, name: 'Swimming Pool' },
    { id: 33, name: 'Showers' }
  ],
};