import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManagerService } from '../services/manager.service';
import { LoaderService } from '../services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Manager {
   id: number
   name: string;
   email: string;
   mobile: string;
   status: number;
   space_id: number;
}

@Component({
   selector: 'delete-manager',
   templateUrl: './delete-manager-dialog.component.html',
   styleUrls: ['./delete-manager-dialog.component.css']
})

export class DeleteManagerDialog {
  public ref;
  public selected_manager;
  public message = '';

  constructor(
     public dialogRef: MatDialogRef<any>,
     private managerService: ManagerService,
     public snackBar: MatSnackBar,
     private loaderService: LoaderService
  ){}

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeDialog (options) {
    this.ref.close(options);
  }

  delete(){
    this.managerService.delete(this.selected_manager.id)
       .then(data => {
          if (data.success){
             this.message = data.message;
             this.closeDialog({success: true, message: data.message});
             this.openSnackBar(this.message, "Dismiss");
          }
       })
       .catch(error => {
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
          console.log(error);
       })
 }

}