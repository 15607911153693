import { Injectable } from "@angular/core";
import * as json2csv from "json2csv";
import { saveAs } from "file-saver";
import { LoaderService } from './loader.service';

@Injectable()
export class CsvDownloadFileService {
  Json2csvParser = json2csv.Parser;
  constructor(
    private _loaderService: LoaderService
  ) { }
  public downloadFile(data: any, fields: Array<string>, filename?: string) {
    this._loaderService.displayLoader(true);
    let csvData = this.convertToCSV(data, fields);
    let file = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(file, filename);
    this._loaderService.displayLoader(false);
  }

  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public capitalizeFirstLetterAndRemoveUnderscore(string) {
    let updated_string = this.capitalizeFirstLetter(string)
    return updated_string.split('_').join(' ');
  }

  public convertToCSV(objArray: any, fields?) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in fields) {
      row += this.capitalizeFirstLetterAndRemoveUnderscore(fields[index]) + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in fields) {
        let head = fields[index];

        line += array[i][head] + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }
}
