import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { MatSnackBar } from "@angular/material/snack-bar";
import { FaqsService } from "../services/faqs.service";
import { SpaceService } from "../services/space.service";
@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit {
  @ViewChild("shortQuestionDialog") shortQuestionDialog: TemplateRef<any>;
  @ViewChild("briefQuestionDialog") briefQuestionDialog: TemplateRef<any>;
  panelOpenState = false;
  selectedCity = 0;
  selectedSpace = 0;
  editor = ClassicEditor;
  data: any = `<p>Hello, world!</p>`;
  toppingList: string[] = [
    "Extra cheese",
    "Mushroom",
    "Onion",
    "Pepperoni",
    "Sausage",
    "Tomato",
  ];
  cities: any[] = [];
  citiesTemp: any[];
  spaces: any[] = [];
  spacesTemp: any[] = [];
  shortQuestions: any[] = [];
  briefQuestions: any[] = [];
  shortQuestionForm: FormGroup;
  briefQuestionForm: FormGroup;
  filtersForm: FormGroup;
  shortQuestionSubmissionMode: boolean = true;
  briefQuestionSubmissionMode: boolean = true;
  selectedQuestion: any;
  locations: any[] = [];
  locationsTempList: any[] = [];
  selectedLocation: any;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private spaceService: SpaceService,
    private faqsService: FaqsService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.createForms();
    this.getAllSpacesCities();
    this.getAllQuestions();
  }

  createForms() {
    this.filtersForm = this.fb.group({
      city_id: 0,
      space_id: 0,
      location_id: 0,
    });
    this.shortQuestionForm = this.fb.group({
      city_id: [0, Validators.required],
      question: ["", [Validators.required]],
      answer: ["", [Validators.required]],

      location_name: [""],
      space_id: [""],
    });
    this.briefQuestionForm = this.fb.group({
      city_id: [0, Validators.required],

      question: ["", [Validators.required]],
      answer: ["", [Validators.required]],

      location_name: [""],
      space_id: [""],
    });
  }

  getAllSpacesCities() {
    this.spaceService.getAllSpacesCities().subscribe(
      (res) => {
        console.log(res);

        this.cities = res || [];
        this.citiesTemp = this.cities.slice();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }

  onCityFilterChange(e: any) {
    console.log(e);
    this.selectedCity = e?.value || 0;
    this.filtersForm.controls.space_id.setValue("");
    this.filtersForm.controls.location_id.setValue("");
    this.getSpacesByCity(this.selectedCity);
    this.getNearBySpacesByCityName(this.returnCityName(this.selectedCity));
    this.getAllQuestions();
    // this.getAllQuestions();
  }
  onLocationFilterChange(e: any) {
    console.log(e);
    this.getSpacesByCityAndLocation(this.selectedCity, e.value);
  }
  getSpacesByCityAndLocation(cityID, locationName) {
    this.spaceService
      .getSpacesByCityAndLocation(this.selectedCity, locationName)
      .subscribe(
        (response) => {
          console.log(response);
          this.spaces = response || [];
          this.spacesTemp = this.spaces.slice();
        },
        ({ message }) => {
          console.log(message);
        },
      );
  }
  onSpaceFilterChange(e) {
    this.selectedSpace = e?.value || 0;
    this.getAllQuestions();
  }
  getAllQuestions() {
    console.log({ filters: this.filtersForm.value });
    this.faqsService
      .getAllQuestions({
        location_name: this.selectedLocation || null,
        space_id: this.selectedSpace || null,
        city_id: this.selectedCity || null,
      })
      .subscribe(
        (response) => {
          console.log(response);
          if (response.length) {
            this.shortQuestions = response.filter((item) => item.type == 1);
            this.briefQuestions = response.filter((item) => item.type == 2);
          } else {
            this.shortQuestions = [];
            this.briefQuestions = [];
          }
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }

  onCitySelectionChange(e) {
    console.log(e);
    this.selectedCity = e?.value || 0;
    this.getSpacesByCity(e.value);
    this.getNearBySpacesByCityName(this.returnCityName(e.value));
  }

  onLocationSelectionChange(e: any) {
    this.selectedLocation = e?.value;
    console.log(e);

    this.getSpacesByCityAndLocation(this.selectedCity, this.selectedLocation);
  }

  onSpaceChange(e) {
    console.log(e);
  }

  getSpacesByCity(cityID) {
    this.spaceService.getSpacesByCity(cityID).subscribe(
      (res) => {
        console.log(res);
        if (res.length == 0) {
          this.spaces = [];
          this.spacesTemp = [];
        }
        this.spaces = res;
        this.spacesTemp = this.spaces.slice();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }
  getNearBySpacesByCityName(cityID) {
    this.spaceService.getNearBySpacesByCityName(cityID).subscribe(
      (res) => {
        console.log(res);
        this.locations = res;
        this.locationsTempList = this.locations.slice();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }
  openShortQuestionDialog() {
    this.shortQuestionForm.reset();
    this.dialog.open(this.shortQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
  }
  openBriefQuestionDialog() {
    this.briefQuestionForm.reset();
    this.dialog.open(this.briefQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
  }

  onSubmitShortQuestion() {
    console.log(this.shortQuestionForm.value);
    if (this.shortQuestionForm.invalid) {
      this.snackBar.open("Please fill all the fields", "Close", {
        duration: 2000,
      });
      return;
    }
    if (this.shortQuestionForm.value.space_id == "" || null) {
      this.shortQuestionForm.value.space_id = 0;
    }
    if (this.shortQuestionForm.value.location_name == "" || null) {
      this.shortQuestionForm.value.location_name = "";
    }
    this.faqsService
      .addQuestion({ type: 1, ...this.shortQuestionForm.value })
      .subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open("Question added successfully", "Close", {
            duration: 2000,
          });
          // this.shortQuestionForm.reset();
          // this.dialog.closeAll();
          this.getAllQuestions();
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }

  onSubmitBriefQuestion() {
    console.log(this.briefQuestionForm.value);
    if (this.briefQuestionForm.invalid) {
      this.snackBar.open("Please fill all the fields", "Close", {
        duration: 2000,
      });
      return;
    }

    if (this.briefQuestionForm.value.space_id == "" || null) {
      this.briefQuestionForm.value.space_id = 0;
    }
    if (this.briefQuestionForm.value.location_name == "" || null) {
      this.briefQuestionForm.value.location_name = "";
    }
    this.faqsService
      .addQuestion({ type: 2, ...this.briefQuestionForm.value })
      .subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open("Question added successfully", "Close", {
            duration: 2000,
          });
          // this.shortQuestionForm.reset();
          // this.dialog.closeAll();
          this.getAllQuestions();
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }

  onUpdateShortQuestion() {
    if (this.shortQuestionForm.invalid) {
      this.snackBar.open("Please fill all the fields", "Close", {
        duration: 2000,
      });
      return;
    }

    if (this.shortQuestionForm.value.space_id == "" || null) {
      this.shortQuestionForm.value.space_id = 0;
    }
    if (this.shortQuestionForm.value.location_name == "" || null) {
      this.shortQuestionForm.value.location_name = "";
    }
    this.faqsService
      .updateQuestion(
        { type: 1, ...this.shortQuestionForm.value },
        this.selectedQuestion.id,
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open("Question updated successfully", "Close", {
            duration: 2000,
          });
          this.getAllQuestions();
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }

  onUpdateBriefQuestion() {
    if (this.briefQuestionForm.invalid) {
      this.snackBar.open("Please fill all the fields", "Close", {
        duration: 2000,
      });
      return;
    }

    if (this.briefQuestionForm.value.space_id == "" || null) {
      this.briefQuestionForm.value.space_id = 0;
    }
    if (this.briefQuestionForm.value.location_name == "" || null) {
      this.briefQuestionForm.value.location_name = "";
    }
    this.faqsService
      .updateQuestion(
        { type: 2, ...this.briefQuestionForm.value },
        this.selectedQuestion.id,
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open("Question updated successfully", "Close", {
            duration: 2000,
          });
          this.getAllQuestions();
        },
        ({ message }) => {
          this.snackBar.open(message, "Close", {
            duration: 2000,
          });
        },
      );
  }
  onDeleteShortQuestion(question) {
    this.faqsService.deleteQuestion(question.id).subscribe(
      (response) => {
        console.log(response);
        this.snackBar.open("Question deleted successfully", "Close", {
          duration: 1500,
        });
        this.getAllQuestions();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }
  onDeleteBriefQuestion(question) {
    this.faqsService.deleteQuestion(question.id).subscribe(
      (response) => {
        console.log(response);
        this.snackBar.open("Question deleted successfully", "Close", {
          duration: 1500,
        });
        this.getAllQuestions();
      },
      ({ message }) => {
        this.snackBar.open(message, "Close", {
          duration: 2000,
        });
      },
    );
  }
  onEditShortQuestion(question) {
    console.log(question);
    this.selectedQuestion = question;
    this.shortQuestionSubmissionMode = false;
    this.getSpacesByCity(question.city_id);
    this.shortQuestionForm.patchValue(question);
    const dialog = this.dialog.open(this.shortQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
    dialog.afterClosed().subscribe(() => {
      this.shortQuestionForm.reset();
      this.shortQuestionSubmissionMode = true;
    });
  }
  onEditBriefQuestion(question) {
    this.selectedQuestion = question;

    this.briefQuestionSubmissionMode = false;
    this.getSpacesByCity(question.city_id);
    this.briefQuestionForm.patchValue(question);
    const dialog = this.dialog.open(this.briefQuestionDialog, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "full-screen-modal",
    });
    dialog.afterClosed().subscribe(() => {
      this.briefQuestionForm.reset();
      this.briefQuestionSubmissionMode = true;
    });
  }

  onFilterChange(e, type) {
    if (type == "city") {
      this.selectedCity = e?.value || null;
      this.filtersForm.controls.space_id.setValue("");
      this.filtersForm.controls.location_id.setValue("");
      this.getSpacesByCity(this.selectedCity);
      this.getNearBySpacesByCityName(this.returnCityName(this.selectedCity));
    }
    if (type == "location") {
      this.selectedLocation = this.getLocationNameById(e?.value) || null;
      this.getSpacesByCityAndLocation(this.selectedCity, this.selectedLocation);
    }
    if (type == "space") {
      this.selectedSpace = e?.value || null;
    }
    this.getAllQuestions();
  }
  resetShortQuestion() {
    this.shortQuestionForm.controls.question.reset();
    this.shortQuestionForm.controls.answer.reset();
  }
  resetBriefQuestion() {
    this.briefQuestionForm.controls.question.reset();
    this.briefQuestionForm.controls.answer.reset();
  }

  resetFiltersForm() {
    this.filtersForm.patchValue({
      city_id: 0,
      space_id: 0,
    });
    this.selectedCity = null;
    this.selectedSpace = null;
    this.selectedLocation = null;
    this.getAllQuestions();
  }

  returnCityName(cityId) {
    return this.cities.find((city) => city.id == cityId).name;
  }

  getLocationNameById(locationId) {
    return this.locations.find((location) => location.id == locationId)
      .location_name;
  }
}
