import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { AppGlobals } from './app-globals';


@Injectable()
export class WorkerService {

  public selectedLocationId;

  constructor(
    private http: HttpClient,
    private _appGlobals: AppGlobals
  ) {
    this._appGlobals.selectedLocationId.subscribe(id => this.selectedLocationId = id);
  }

  private base_url = 'api/v1/admin';
  private getAllUrl = environment.apiUrl + this.base_url + '/workers';
  private updateUrl = environment.apiUrl + this.base_url + '/update_worker';
  private deleteUrl = environment.apiUrl + this.base_url + '/delete_worker';
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  private _options = {
    headers: this.headers,
    withCredentials: true
  };

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAll(): Promise<any> {

    return this.http.get(this.getAllUrl,
      {
        withCredentials: true
      })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  update(worker_id, data): Promise<any> {
    return this.http
      .put(this.updateUrl + '/' + worker_id, JSON.stringify(data), { headers: this.headers, withCredentials: true })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  delete(worker_id): Promise<any> {
    return this.http
      .delete(this.deleteUrl + '/' + worker_id, { headers: this.headers, withCredentials: true })
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }
}
