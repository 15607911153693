import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MeetingRoomService } from '../services/meetingRoom.service';
import { LoaderService } from '../services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class MeetingRoom {
  id: number;
  name: string;
  price: number;
  capacity: number;
  space_name: string;
}

@Component({
  selector: 'delete-meetingroom',
  templateUrl: './delete-meetingroom.component.html',
  styleUrls: ['./delete-meetingroom.component.css']
})

export class DeleteMeetingRoomDialog {
  public ref;
  public selected_meetingroom;
  public message = '';

  constructor(
    public dialogRef: MatDialogRef<any>,
    private meetingroomService: MeetingRoomService,
    public snackBar: MatSnackBar,
    private loaderService: LoaderService
  ) { }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeDialog(options) {
    this.ref.close(options);
  }
  delete() {
    this.meetingroomService.deleteDetails(this.selected_meetingroom.id)
      .then(data => {
        if (data.success) {
          this.message = data.message;
          this.closeDialog({ success: true, message: data.message });
          this.openSnackBar(this.message, "Dismiss");
        }
      })
      .catch(error => {
        this.message = "Something went wrong";
        this.openSnackBar(this.message, "Dismiss");
        console.log(error);
      })
  }
}