import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, pipe } from "rxjs";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { LoaderService } from "./loader.service";

@Injectable({
  providedIn: "root",
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(private loaderService: LoaderService, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.showLoader();
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body && event.body.not_verified) {
              this.router.navigate(['login']);
            }
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
        },
      ),
    );
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.displayLoader(true);
  }
  private hideLoader(): void {
    this.loaderService.displayLoader(false);
  }
}
