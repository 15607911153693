import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SendProposal } from "src/app/models/send-proposal";


@Component({
    selector: 'email-preview',
    templateUrl: './email-preview.component.html',
    styleUrls: ['./email-preview.component.css']
})

export class EmailPreviewComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public _data: SendProposal, 
    public _ref: MatDialogRef<EmailPreviewComponent>) { }
    ngOnInit(): void {
        console.log(this._data, 'data');
    }
}