import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MeetingRoomService } from '../services/meetingRoom.service';
import { LoaderService } from '../services/loader.service';
import { AppGlobals } from '../services/app-globals';
import { MatSnackBar } from '@angular/material/snack-bar';

export class MeetingRoom {
  id: number;
  name: string;
  price: number;
  space_id: number;
  capacity: number;
  is_active: any;
  space_name: string;
}

@Component({
  selector: 'update-meetingroom',
  templateUrl: './add-update-meetingroom-dialog.component.html',
  styleUrls: ['./add-update-meetingroom-dialog.component.css']
})

export class UpdateMeetingRoomDialog {
  public meetingroom: MeetingRoom;
  public ref;
  public selected_meetingroom: MeetingRoom;
  public spaces_data;
  public action_type;
  public message;
  public statusArray = [{
    id: 1,
    name: 'Active'
  },
  {
    id: 0,
    name: 'In-Active'
  }];
  constructor(
    public dialogRef: MatDialogRef<any>,
    private meetingroomService: MeetingRoomService,
    public snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private _appGlobals: AppGlobals,
  ) {
    this._appGlobals.spacesData.subscribe(spaces => this.spaces_data = spaces);
    this.meetingroom = new MeetingRoom;
  }
  ngOnInit() {
    if (this.action_type == 2) {
      this.meetingroom = Object.assign({}, this.selected_meetingroom);
    }
  }

  addOrUpdate() {
    let final_data = Object.assign({}, this.meetingroom);

    if (this.action_type == 2) {
      this.meetingroomService.updateDetails(this.selected_meetingroom.id, final_data)
        .then(data => {
          if (data.success) {
            this.message = data.message;
            this.closeDialog({ success: true, message: data.message });
            this.openSnackBar(this.message, "Dismiss");
          } else if (!data.success) {
            this.message = data.message;
            this.openSnackBar(this.message, "Dismiss");
          }
        })
        .catch(error => {
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
          console.log(error);
        })
    } else {
      this.meetingroomService.addDetails(final_data)
        .then(data => {
          if (data.success) {
            this.message = data.message;
            this.closeDialog({ success: true, message: data.message });
            this.openSnackBar(this.message, "Dismiss");
          } else {
            this.message = data.message;
            this.openSnackBar(this.message, "Dismiss");
          }
        })
        .catch(error => {
          this.message = "Something went wrong";
          this.openSnackBar(this.message, "Dismiss");
          console.log(error);
        })
    }
  }

  openSnackBar(message: string, action: string) {
    this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  closeDialog(options) {
    this.ref.close(options);
  }
}
