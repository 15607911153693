<div>
  <div>
    <label *ngIf="action_type == 1" class="top-label">Add Team Cabin details</label>
    <label *ngIf="action_type == 2" class="top-label">Update Team Cabin details</label>
    <i class=" cross material-icons" (click)="closeDialog(null)">
      close
    </i>
  </div>
  <form #spaceForm="ngForm" (ngSubmit)="addOrUpdate()" class="space-form">
    <mat-form-field class="space-form-inputs" appearance="outline">
      <mat-label>Name</mat-label>
      <input type="string" matInput placeholder="Name" name="name" [(ngModel)]="teamcabin.name" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Price</mat-label>
      <input type="number" matInput placeholder="Price" name="price" [(ngModel)]="teamcabin.price" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="teamcabin.is_active" name="status" required>
        <mat-option *ngFor="let status of statusArray" [value]="status.id">
          {{status.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Capacity</mat-label>
      <input type="number" matInput placeholder="Capacity" name="capacity" [(ngModel)]="teamcabin.capacity" required>
    </mat-form-field>
    <mat-form-field class="space-small-form-inputs" appearance="outline">
      <mat-label>Select Space</mat-label>
      <mat-select [(ngModel)]="teamcabin.space_id" name="space_id" required>
        <mat-option *ngFor="let space of spaces_data" [value]="space.id">
          {{space.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" type="submit" [disabled]="!spaceForm.form.valid">Submit</button>
    </div>
  </form>
</div>