<div class="page-wrapper">
  <!--Sidebar Menu Starts-->

  <!--Sidebar Menu ends-->

  <!--Dashboard content Wrapper starts-->
  <div class="dash-content-wrap">
    <!--Dashboard breadcrumb starts-->
    <div class="dash-breadcrumb">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="dash-breadcrumb-content">
              <div class="dash-breadcrumb-left">
                <div class="breadcrumb-menu text-right sm-left">
                  <p class="list-address">
                    Showing <b>{{ leads_data.length }}</b> results
                  </p>
                </div>
              </div>
              <div>
                <button class="btn v3" (click)="downloadCSV()">
                  <i class="ion-plus-round"></i>Export to CSV</button
                >&nbsp;&nbsp;
                <button class="btn v3" (click)="addLeadDialog()">
                  <i class="ion-plus-round"></i>Add Lead
                </button>
              </div>
              <!-- <a class="btn v3" href="add-listing.html"><i class="ion-plus-round"></i>Add Listing </a> -->
            </div>
            <!-- <div style="display: flex; justify-content: flex-end;">

            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!--Dashboard breadcrumb ends-->
    <!--Dashboard content starts-->
    <div class="dash-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="dash-bookings">
              <div class="act-title">
                <h5><i class="ion-ios-copy-outline"></i> Leads</h5>
              </div>
              <div class="db-booking-wrap" *ngFor="let element of leads_data">
                <div class="db-booking-item">
                  <h4>{{ element.name }}</h4>
                  <span
                    ><i class="ion-android-call"></i> &nbsp;{{
                      element.mobile
                    }}</span
                  >
                  &nbsp; &nbsp;
                  <span
                    ><i class="ion-android-mail"></i> &nbsp;{{
                      element.email
                    }}</span
                  >

                  <div class="booking-info" style="margin-top: 10px">
                    <h6>ID:</h6>
                    <ul class="booking_list">
                      <li>
                        <span>{{ element.id }}</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="booking-info"
                    *ngIf="element.location_name && element.city_name"
                  >
                    <h6>Location Name:</h6>
                    <ul class="booking_list">
                      <li>
                        <span
                          >{{
                            element.location_name
                              ? element.location_name + ", "
                              : ""
                          }}{{ element.city_name | titlecase }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <div
                    class="booking-info"
                    *ngIf="element.city_name && !element.location_name"
                  >
                    <h6>City Name:</h6>
                    <ul class="booking_list">
                      <li>
                        <span>{{ element.city_name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="booking-info" *ngIf="element.space_id">
                    <h6>Space Name:</h6>
                    <ul class="booking_list">
                      <li>
                        <span>{{ element.actual_space_name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="booking-info">
                    <h6>Resource Name:</h6>
                    <ul class="booking_list">
                      <li>
                        <span>{{ element.resource_name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="booking-info" *ngIf="element.company_name">
                    <h6>Company:</h6>
                    <ul class="booking_list transparent">
                      <li>
                        <span>{{ element.company_name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="booking-info">
                    <h6>Persons:</h6>
                    <ul class="booking_list">
                      <li>
                        <span>{{ element.persons }}</span>
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="booking-info">
                    <h6>Resource: </h6>
                    <ul class="booking_list">
                      <li><span>{{resource_type_names[element.resource_type]}}
                          {{element.booking_date_time && (element.resource_type == 6 || element.resource_type == 7)? 'at '+element.booking_date_time: ''}}</span>
                      </li>
                    </ul>
                  </div> -->
                  <div class="booking-info">
                    <h6>Added at:</h6>
                    <ul class="booking_list">
                      <li>
                        <span>{{ element.added_at }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="booking-info">
                    <h6>Verified:</h6>
                    <ul class="booking_list">
                      <li>
                        <span
                          class="text-success"
                          *ngIf="element?.verified == 1"
                          >Verified</span
                        >
                        <span class="text-danger" *ngIf="element?.verified == 0"
                          >Unverified Mobile</span
                        >
                      </li>
                    </ul>
                  </div>
                  <!-- <a class="btn v8 mt-4" (click)="sendMessageDialog(element, 1)"
                    *ngIf="element.city_name && element.location_name"><i class="ion-android-mail"></i> Send
                    Proposal</a>
                  <a class="btn v8 mt-4" (click)="sendMessageDialog(element, 2)"
                    *ngIf="element.city_name && !element.location_name"><i class="ion-android-mail"></i> Send
                    Proposal</a> -->
                  <a class="btn v8 mt-4" (click)="sendMessageDialog(element)"
                    ><i class="ion-android-mail"></i> Send Proposal</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dashboard content ends-->
    <!--Dashboard footer ends-->
  </div>
  <!--Dashboard content Wrapper ends-->
</div>
