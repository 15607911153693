<div class="list-details-section section-padding add_list pad-top-90">
  <div class="dash-content-wrap">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs list-details-tab" *ngIf="!is_id_exist">
            <li class="nav-item" [ngClass]="{'active': page1}" (click)="page1_filled ? navHelper(1) : false">
              <a data-toggle="tab" [ngClass]="{'disabled': !page1_filled, 'enabled': page1_filled}"><span
                  [ngClass]="{'text-grey': !page1}">General
                  Information</span></a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page2}" (click)="page2_filled ? navHelper(2) : false">
              <a data-toggle="tab" [ngClass]="{'disabled': !page2_filled, 'enabled': page2_filled}"><span
                  [ngClass]="{'text-grey': !page2}">Location
                  /Contacts</span></a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page3}" (click)="page3_filled ? navHelper(3) : false">
              <a data-toggle="tab" [ngClass]="{'disabled': !page3_filled, 'enabled': page3_filled}"><span
                  [ngClass]="{'text-grey': !page3}">Business
                  Hours</span></a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page4}" (click)="page4_filled ? navHelper(4) : false">
              <a data-toggle="tab" [ngClass]="{'disabled': !page4_filled, 'enabled': page4_filled}"><span
                  [ngClass]="{'text-grey': !page4}">
                  Inventory/Pricing</span></a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page5}" (click)="page5_filled ? navHelper(5) : false">
              <a data-toggle="tab" [ngClass]="{'disabled': !page5_filled, 'enabled': page5_filled}"><span
                  [ngClass]="{'text-grey': !page5}">Contact Details</span></a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page6}" (click)="page6_filled ? navHelper(6) : false">
              <a data-toggle="tab" [ngClass]="{'disabled': !page6_filled, 'enabled': page6_filled}"><span
                  [ngClass]="{'text-grey': !page6}">Gallery</span></a>
            </li>
            <!-- <li class="nav-item">
                        <a data-toggle="tab" href="#social_network">Social Networks <span class="text-grey"></span></a>
                    </li> -->
          </ul>
          <ul class="nav nav-tabs list-details-tab" *ngIf="is_id_exist">
            <li class="nav-item" [ngClass]="{'active': page1 == true}" (click)="navHelper(1)">
              <a class="a-cursor" data-toggle="tab">General Information</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page2 == true}" (click)="navHelper(2)">
              <a class="a-cursor" data-toggle="tab">Location /Contacts</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page3 == true}" (click)="navHelper(3)">
              <a class="a-cursor" data-toggle="tab">Business Hours</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page4 == true}" (click)="navHelper(4)">
              <a class="a-cursor" data-toggle="tab">Inventory/Pricing</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page5 == true}" (click)="navHelper(5)">
              <a class="a-cursor" data-toggle="tab">Contact Details</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': page6 == true}" (click)="navHelper(6)">
              <a class="a-cursor" data-toggle="tab">Gallery</a>
            </li>
            <!-- <li class="nav-item">
                                  <a data-toggle="tab" href="#social_network">Social Networks <span class="text-grey"></span></a>
                              </li> -->
          </ul>
          <div class="tab-content mar-tb-30 add_list_content">
            <div id="general_info" *ngIf="page1">
              <h4> <i class="ion-ios-information"></i> General Information :</h4>
              <div class="row">
                <!-- <div class="col-md-6" *ngIf="false">
                <div class="form-group">
                  <label>Listing title</label>
                  <input type="text" class="form-control filter-input" placeholder="Enter name">
                </div>
              </div> -->

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="required">Actual Name</label>
                    <input type="text" [(ngModel)]="space_details.actual_name" class="form-control filter-input"
                      [ngClass]="{'color-outline': page1_important && !space_details.actual_name}"
                      placeholder="Enter Actual">
                    <label *ngIf="page1_important && !space_details.actual_name" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Max Capacity</label>
                    <input type="number" [(ngModel)]="space_details.capacity" class="form-control filter-input"
                      placeholder="Enter Max Capacity">
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label>Keywords</label>
                    <input type="text" class="form-control filter-input"
                      placeholder="Keywords should be separated by commas">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control filter-input" placeholder="Address of your hotel">
                  </div>
                </div>-->
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="list_info" class="required">Description</label>
                    <textarea class="form-control" [(ngModel)]="space_details.about" name="about" id="list_info"
                      rows="4" [ngClass]="{'color-outline': page1_important && !space_details.about}"
                      placeholder="Enter your text here" required></textarea>
                    <label *ngIf="page1_important && !space_details.about" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Rating</label>
                    <mat-select placeholder="Rating" [(ngModel)]="space_details.rating">
                      <mat-option *ngFor="let rating of ratings" [value]="rating">
                        {{rating}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="required">Amenities</label>
                    <div class="filter-checkbox">
                      <span *ngFor="let facility of facilities_list">
                        <input [id]="'fac-'+facility.id" type="checkbox"
                          [checked]="space_facilities.includes(facility.id)" name="check"
                          (change)="updateSpaceFacilities(facility.id, $event)">
                        <label [for]="'fac-'+facility.id">{{facility.name}}</label>
                      </span>

                    </div>
                    <label *ngIf="page1_important && !facility_clicked" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                  <!--  <div class="row">
                    <div class="col-md-6">
                      <label>Title</label>
                      <input type="text" class="form-control filter-input" placeholder="Title">
                    </div>

                    <div class="col-md-6">
                      <label>Icon</label>
                      <input type="text" class="form-control filter-input" placeholder="Icon">
                    </div>
                    <div class="col-md-4">
                      <a href="#" class="btn v7 mar-top-20">Add Facts</a>
                    </div>
                  </div> -->

                  <div class="col-md-4" *ngIf="!is_id_exist">
                    <a data-toggle="tab" (click)="nextHelper(1)" class="btn v8 mar-top-20"><span>Next
                        Step</span></a>
                  </div>
                </div>

              </div>
            </div>

            <div *ngIf="page2">
              <h4><i class="ion-ios-location"></i> Location/Contacts :</h4>
              <div class="row">
                <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>Start typing location</label>
                  <input type="text" class="form-control filter-input" placeholder="Enter name">
                </div>
              </div> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="required">Landmark</label>
                    <input type="text" maxlength="50" [(ngModel)]="space_details.landmark" name="about"
                      class="form-control filter-input" placeholder="Enter Landmark"
                      [ngClass]="{'color-outline': page2_important && !space_details.landmark}">
                    <label *ngIf="page2_important && !space_details.landmark" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="required">
                      Longitude</label>
                    <input type="number" min="-180" max="180" [(ngModel)]="space_details.longi" name="longi"
                      class="form-control filter-input" placeholder="Map Longitude"
                      [ngClass]="{'color-outline': page2_important && !space_details.longi}">
                    <label *ngIf="page2_important && !space_details.longi" style="color: red;">Field cannot be
                      empty</label>
                    <label
                      *ngIf="page2_important && !(space_details.longi >= -180 && space_details.lat <= 180) && space_details.longi"
                      style="color: red;">Invalid
                      Longitude</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="required">
                      Latitude</label>
                    <input type="number" min="-90" max="90" [(ngModel)]="space_details.lat" name="lat"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page2_important && !space_details.lat}" placeholder="Map Latitude">
                    <label *ngIf="page2_important && !space_details.lat" style="color: red;">Field cannot be
                      empty</label>
                    <label
                      *ngIf="page2_important && !(space_details.lat >= -90 && space_details.lat <= 90) && space_details.lat"
                      style="color: red;">Invalid
                      Latitude</label>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="is_id_exist">
                  <div class="form-group">
                    <label>
                      Location Name</label>
                    <input type="text" [(ngModel)]="space_details.location_name" name="location_name"
                      class="form-control filter-input" placeholder="Location Name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Nearest metro name</label>
                    <input type="text" [(ngModel)]="space_details.near_by_metro" name="near_by_metro"
                      class="form-control filter-input" placeholder="Nearest metro">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Nearest metro distance</label>
                    <input type="text" [(ngModel)]="space_details.metro_distance" name="metro_distance"
                      class="form-control filter-input" placeholder="Nearest metro">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Nearest railway station name</label>
                    <input type="text" [(ngModel)]="space_details.near_by_railway" name="near_by_railway"
                      class="form-control filter-input" placeholder="Nearest Railway station">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Nearest railway station distance</label>
                    <input type="text" [(ngModel)]="space_details.railway_distance" name="railway_distance"
                      class="form-control filter-input" placeholder="Nearest Railway Station Distance">
                  </div>
                </div>
                <!--               <div class="col-md-12 no-padding">
                <div id="map"></div>
              </div>
              <div class="col-md-6">
                <div class="form-group mar-top-15">
                  <label>Website </label>
                  <input type="text" class="form-control filter-input">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mar-top-15">
                  <label>Phone </label>
                  <input type="text" class="form-control filter-input">
                </div>
              </div> -->
              </div>
              <div class="col-md-4" *ngIf="!is_id_exist">
                <a (click)="nextHelper(2)" class="btn v8 mar-top-20">Next Step</a>
              </div>
            </div>

            <div id="open_time" *ngIf="page3">
              <h4><i class="ion-clock"></i> Opening/Business Hours :</h4>
              <div class="row mar-bot-30">
                <div class="col-md-5">
                  <mat-checkbox [(ngModel)]="has_247_access_toggle" [checked]="has_247_access_toggle">
                    Has 24x7 Access
                  </mat-checkbox>
                </div>
              </div>
              <div class="row mar-bot-30">
                <div class="col-md-2">
                  <label class="fix_spacing">Monday-Friday</label>
                </div>
                <div class="col-md-5">
                  <label>Opening Time</label>
                  <input type="time" [(ngModel)]="space_details.mon_friday_opening_time" name="mon_friday_opening_time"
                    class="form-control filter-input" name="open_time">
                </div>
                <div class="col-md-5">
                  <label>Closing Time</label>
                  <input type="time" [(ngModel)]="space_details.mon_friday_closing_time" name="mon_friday_closing_time"
                    class="form-control filter-input" name="open_time">
                </div>
              </div>
              <div class="row mar-bot-30">
                <div class="col-md-5">
                  <mat-checkbox [checked]="is_saturday_closed" name="check" (change)="satSunClosed(1, $event)">
                    Saturday Closed</mat-checkbox>
                </div>

                <div class="col-md-5">
                  <mat-checkbox [checked]="is_sunday_closed" name="check" (change)="satSunClosed(2, $event)">
                    Sunday Closed</mat-checkbox>
                </div>
              </div>
              <div class="row mar-bot-30">
                <div class="col-md-2">
                  <label class="fix_spacing">Saturday</label>
                </div>
                <div class="col-md-5">
                  <label>Opening Time</label>
                  <input type="time" [(ngModel)]="space_details.saturday_opening_time" name="saturday_open_time"
                    class="form-control filter-input" name="open_time" [disabled]="is_saturday_closed">
                </div>
                <div class="col-md-5">
                  <label>Closing Time</label>
                  <input type="time" [(ngModel)]="space_details.saturday_closing_time" name="saturday_closing_time"
                    class="form-control filter-input" name="open_time" [disabled]="is_saturday_closed">
                </div>

              </div>

              <div class="row mar-bot-30">
                <div class="col-md-2">
                  <label class="fix_spacing">Sunday</label>
                </div>
                <div class="col-md-5">
                  <label>Opening Time</label>
                  <input type="time" [(ngModel)]="space_details.sunday_opening_time" name="sunday_opening_time"
                    class="form-control filter-input" name="open_time" [disabled]="is_sunday_closed">
                </div>
                <div class="col-md-5">
                  <label>Closing Time</label>
                  <input type="time" [(ngModel)]="space_details.sunday_closing_time" name="sunday_closing_time"
                    class="form-control filter-input" name="open_time" [disabled]="is_sunday_closed">
                </div>
              </div>
              <div class="col-md-4" *ngIf="!is_id_exist">
                <a (click)="nextHelper(3)" class="btn v8 mar-top-20">Next step</a>
              </div>
            </div>
            <div id="rooms" *ngIf="page4">
              <h4><i class="ion-ios-location"></i> Resources/Pricing :</h4>
              <div class="row">
                <div class="col-md-12">
                  <label>Select available resources</label>
                  <div class="form-group">
                    <div class="filter-checkbox">
                      <span *ngFor="let resource_type of resource_types">
                        <input type="checkbox" [id]="'res-check-'+resource_type.id" name="check" value=""
                          [checked]="res_array.includes(resource_type.id)"
                          (change)="setMinimumResourcePriceVisible(resource_type.id, $event)">
                        <label [for]="'res-check-'+resource_type.id">{{resource_type.name}}</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" *ngFor="let resource_type of resource_types">
                  <div class="form-group">
                    <label>{{resource_type.name}} price starts at</label>
                    <input type="number" class="form-control filter-input" [id]='"res-" + resource_type.id'
                      [(ngModel)]="space_details[resource_type.column_name]"
                      placeholder='Enter starting price of {{resource_type.name}}'
                      [disabled]="!space_details[resource_type.column_name]">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label style="color: red" *ngIf="price_message">{{price_message}}</label>
              </div>
              <div class="col-md-4" *ngIf="!is_id_exist">
                <a (click)="nextHelper(4)" class="btn v8 mar-top-20">Next step</a>
              </div>
            </div>
            <div id="contact-info" *ngIf="page5">
              <h4><i class="ion-ios-location"></i> Contact Details :</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" name="address" [(ngModel)]="space_details.address"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.address}"
                      placeholder="Enter Address">
                    <label *ngIf="page5_important && !space_details.address" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>City</label>
                    <input type="text" name="city" [(ngModel)]="space_details.contact_city_name" class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.contact_city_name}" placeholder="Enter City">
                    <label *ngIf="page5_important && !space_details.contact_city_name" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>State</label>
                    <input type="text" name="state" [(ngModel)]="space_details.state" class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.state}" placeholder="Enter State">
                    <label *ngIf="page5_important && !space_details.state" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Country</label>
                    <input type="text" name="country" [(ngModel)]="space_details.country"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.country}"
                      placeholder="Enter Country">
                    <label *ngIf="page5_important && !space_details.country" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Zip Code</label>
                    <input type="text" name="zip_code" [(ngModel)]="space_details.zip_code"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.zip_code}"
                      placeholder="Enter Zip Code">
                    <label *ngIf="page5_important && !space_details.zip_code" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Contact Name</label>
                    <input type="text" name="contact_name" [(ngModel)]="space_details.contact_name"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.contact_name}"
                      placeholder="Enter Name">
                    <label *ngIf="page5_important && !space_details.contact_name" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" name="contact_email" [(ngModel)]="space_details.contact_email"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.contact_email}"
                      placeholder="Enter Email">
                    <label *ngIf="page5_important && !space_details.contact_email" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Mobile</label>
                    <input type="text" name="contact_mobile" [(ngModel)]="space_details.contact_mobile"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.contact_mobile}"
                      placeholder="Enter Mobile">
                    <label *ngIf="page5_important && !space_details.contact_mobile" style="color: red;">Field cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Alternate Contact Name</label>
                    <input type="text" name="alternate_contact_name" [(ngModel)]="space_details.alternate_contact_name"
                      class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.alternate_contact_name}"
                      placeholder="Enter Name">
                    <label *ngIf="page5_important && !space_details.alternate_contact_name" style="color: red;">Field
                      cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" name="alternate_contact_email"
                      [(ngModel)]="space_details.alternate_contact_email" class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.alternate_contact_email}"
                      placeholder="Enter Email">
                    <label *ngIf="page5_important && !space_details.alternate_contact_email" style="color: red;">Field
                      cannot be
                      empty</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Mobile</label>
                    <input type="text" name="alternate_contact_mobile"
                      [(ngModel)]="space_details.alternate_contact_mobile" class="form-control filter-input"
                      [ngClass]="{'color-outline': page5_important && !space_details.alternate_contact_mobile}"
                      placeholder="Enter Mobile">
                    <label *ngIf="page5_important && !space_details.alternate_contact_mobile" style="color: red;">Field
                      cannot be
                      empty</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <a *ngIf="!is_id_exist" (click)="geocode();" class="btn v8 mar-top-20">Submit and Create Space</a>
                <a *ngIf="is_id_exist" (click)="geocode();" class="btn v8 mar-top-20">Update Space details</a>
              </div>
            </div>
            <div id="gallery" *ngIf="page6">
              <h4><i class="ion-image"></i> Gallery :</h4>
              <div class="form-group">
                <form class="photo-upload">
                  <div class="form-group">
                    <div class="add-listing__input-file-box">
                      <input class="add-listing__input-file" type="file" name="file" id="file" multiple
                        (change)="fileChangeEvent($event)" accept="image/jpeg, image/png">
                      <div class="add-listing__input-file-wrap">
                        <i class="ion-ios-cloud-upload"></i>
                        <p>Click here to upload your images</p>
                      </div>
                    </div>
                  </div>
                </form>
                <ul>
                  <li class="file-list" *ngFor="let file of filesToUpload"
                    style.color="getSizeInMb(file.size) > 3 ? 'red': 'unset'">
                    <span style="flex: 2">{{file.name}}</span><span style="flex: 1">{{getSizeInMb(file.size)}}
                      MB</span><span class="ion-close"></span>
                  </li>
                  <hr>
                </ul>
                <div class="add-btn" *ngIf="filesToUpload?.length > 0">
                  <button (click)="uploadImages();" [disabled]="is_add_button_disabled" class="btn v8 mar-top-20">Add
                    Images</button>
                </div>
              </div>
              <div class="image-container">
                <span *ngFor="let element of space_details.images">
                  <input class="image-checkbox" type="checkbox" name="check" value=""
                    (click)="deleteImage(element, $event)">
                  <img class="item" [src]="aws_base_url + space_details.id + '/' + element"
                    (click)="showImageDialog(element)" alt="Details image">
                </span>
              </div>
              <div class="add-btn">
                <a *ngIf="delete_images && delete_images.length" (click)="deleteImageDialog()"
                  class="btn v8 mar-top-20">Delete
                  Images</a> &nbsp;&nbsp;
                <a *ngIf="is_id_exist && delete_images && delete_images.length == 1" (click)="makeCoverImage()"
                  class="btn v8 mar-top-20">Make as Cover Image</a>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Youtube Url</label>
                    <input type="text" name="youtube_url" [(ngModel)]="space_details.youtube_url"
                      class="form-control filter-input" placeholder="Enter youtube url">
                  </div>
                  <div class="add-btn">
                    <button
                      class="btn v8 mar-top-20" (click)="geocode()">Update</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane fade" id="social_network">
            <h4><i class="icofont-ui-social-link"></i>Social Networks:</h4>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Facebook link (Optional)</label>
                  <input type="text" class="form-control filter-input" placeholder="Facebook url">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Pinterest (Optional)</label>
                  <input type="text" class="form-control filter-input" placeholder="Pinterest url">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Twitter link (Optional)</label>
                  <input type="text" class="form-control filter-input" placeholder="Twitter url">
                </div>
              </div>
              <div class="col-md-6 text-left">
                <div class="res-box mar-top-10">
                  <input type="checkbox" tabindex="3" class="" name="remember" id="remember">
                  <label for="remember">I've read and accept <a href="terms.html">terms &amp;
                      conditions</a></label>
                </div>
              </div>
              <div class="col-md-6 text-right sm-left">
                <button class="btn v8" type="submit">Preview</button>
                <button class="btn v8" type="submit">Submit</button>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>