<!--Dashboard breadcrumb ends-->

<!-- Dashboard Content starts-->
<div class="row dash-content-wrap2" style="padding: 20px 5px;">
  <div class="col-md-12">
    <div class="dash-breadcrumb-content">
      <div class="dash-breadcrumb-left">
        <!-- <div class="breadcrumb-menu text-right sm-left"> -->
        <!-- <p class="list-address">Showing <b>{{filtered_spaces_data?.length}}</b> spaces</p> -->
        <div class="first-container">
          <ul class="nav-container">
            <li class="tabs" [ngClass]="{'tab-active':selected_status == null}" (click)="changeStatus(null)">
              All
            </li>
            <li class="tabs" [ngClass]="{'tab-active':selected_status == 1}" (click)="changeStatus(1)">
              Active
            </li>
            <li class="tabs" [ngClass]="{'tab-active':selected_status == 0}" (click)="changeStatus(0)">
              Inactive
            </li>
          </ul>
        </div>

        <!-- </div> -->
      </div>
      <a class="btn v3" href="/home/add-space"><i class="ion-plus-round"></i>Add Space </a>
    </div>
  </div>
</div>
<div class="row dash-content-wrap2">
  <div class="col-md-12">
    <div class="recent-activity my-listing">
      <div class="act-title">
        <h5><i class="ion-social-buffer-outline"></i>Spaces</h5>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 100%; margin-top: 10px;">
              <mat-label>Search space name</mat-label>
              <input type="text" matInput [(ngModel)]="search_string" placeholder="Search space name">
              <mat-icon *ngIf="search_string" matSuffix style="cursor: pointer;" (click)="clearSearchInput()">
                cancel
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6" style="align-self: center;">
            <button class="btn v8" [disabled]="!search_string" (click)="applyFilter()">Search</button>
          </div>
        </div>
        <p class="list-address">Showing <b>{{filtered_spaces_data?.length}}</b> spaces</p>
      </div>
      <div class="viewd-item-wrap" *ngFor="let element of filtered_spaces_data">
        <div class="most-viewed-item">
          <!--  <div class="most-viewed-img">
            <a href="#"><img src="images/single-listing/gallery-6.jpg" alt="..."></a>
          </div> -->
          <div class="most-viewed-detail">
            <!-- <a class="category" href="#"><span class="list-bg aqua"><i class="icofont-hotel"></i></span>Hotel</a> -->
            <!-- <div style="align-self: center;" [ngStyle]="{'color':'green'}">
                                                <i class="material-icons">verified</i>
                                              </div> -->
            <h3><a href="{{website_base_url}}/coworking-space/{{element.link_name}}">{{element.name}}</a><span
                class="book-cancel" *ngIf="element.status == 0">In-Active</span><span class="book-approved"
                *ngIf="element.status == 1">Active</span>&nbsp;<span class="book-pending"
                *ngIf="element.is_veriifed">Verified</span>
            </h3>

            <p class="list-address"><i class="icofont-google-map"></i>{{element.city_name}}</p>
            <div class="ratings">
              <i class="ion-ios-star"></i>
              <i class="ion-ios-star"></i>
              <i class="ion-ios-star"></i>
              <i class="ion-ios-star"></i>
              <i class="ion-ios-star-half"></i>
            </div>
            <div class="views">Actual Name : <span>{{element.actual_name}}</span></div><br>
            <div><br>
              <mat-slide-toggle [(ngModel)]="element.space_status_toggle" color="#f76900"
                (ngModelChange)="updateSpace(element)">
                {{!element.space_status_toggle ? 'Activate Space' : 'Active Space'}}
              </mat-slide-toggle>
            </div>
          </div>
          <div class="listing-button">
            <a routerLink="/home/update-space/{{element.id}}" class="btn v2"><i class="ion-edit"></i> Edit</a>
            <button (click)="openDeleteSpaceDialog(element)" class="btn v5"><i class="ion-android-delete"></i>
              Delete</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<!-- Dashboard Content ends-->