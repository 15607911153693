import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from '../global/global-variables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpaceService } from '../services/space.service';
import { LoaderService } from '../services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Manager {
    id: number
    name: string;
    email: string;
    mobile: string;
    status: number;
    space_id: number;
}

@Component({
    selector: 'delete-manager',
    templateUrl: './delete-image-dialog.component.html',
    styleUrls: ['./delete-image-dialog.component.css']
})

export class DeleteImageDialog {
    public ref;
    public id;
    public delete_images;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private spaceService: SpaceService,
        public snackBar: MatSnackBar,
        private loaderService: LoaderService
    ) { }

    openSnackBar(message: string, action: string) {
        this.loaderService.displayLoader(false);
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    }

    closeDialog(options) {
        this.ref.close(options);
    }

    delete() {
        this.spaceService.deleteImages(this.id, this.delete_images)
            .then(res => {
                if (res && res.success) {
                    this.closeDialog({ success: true, message: res.message });
                    this.openSnackBar(res.message, "Dismiss");
                }
            })
            .catch(err => {
                throw err;
            })
    }

}